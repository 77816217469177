import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Typography,
  Grid,
  Avatar,
  Tab,
  Tabs,
  Button,
} from "@material-ui/core";
import { db } from "../../../store/firebase/firebase";
import { doc, getDoc } from "firebase/firestore";

import About from "./About";
import Projects from "./Projects";

const AntTabs = withStyles({
  root: {
    borderBottom: "4px solid #E7E9EE",
    marginTop: "68px",
  },
  indicator: {
    backgroundColor: "rgba(24, 14, 64, 1)",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    paddingLeft: "12px",
    fontSize: "12px",

    "&$selected": {
      opacity: 1,
      backgroundColor: "#F6F6F6",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 6),
  },
  cardContent: {
    background: "#fff",
    boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
    padding: theme.spacing(3, 1),
    height: "100%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    marginRight: theme.spacing(2),
  },
  pages: {
    marginBlock: theme.spacing(3.5),
  },
}));

const ConnectProfileDetails = (props) => {
  const classes = useStyles();
  const [selectedTab, setValue] = useState(0);
  const [about, setAbout] = useState();
  const { next, previous } = props.navigation;
  const { userId } = props;
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDocRef = doc(db, "users", userId);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          setAbout(docSnapshot.data());
        } else {
          console.log("No such User!");
        }
      } catch (error) {
        console.error("Error getting user:", error);
      }
    };
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={12} md={8}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              className={classes.avatar}
              src={about && about.profilePhoto}
            />
            <div>
              <Typography gutterBottom variant="h5">
                {about && about.name ? about.name : ""}
              </Typography>
              <Typography>
                {about && about.bio ? about.bio.substring(0, 70) : "..."}
              </Typography>
              <div style={{ display: "flex" }}></div>
            </div>
          </div>
          <Button
            onClick={previous}
            variant="outlined"
            style={{ marginTop: "30px" }}
          >
            Back to Profiles
          </Button>
        </Grid>
      </Grid>

      <AntTabs value={selectedTab} onChange={(event, value) => setValue(value)}>
        <AntTab label="About" />
        <AntTab label="Projects" />
        {/* <AntTab label="Activity" /> */}
      </AntTabs>
      <div className={classes.pages}>
        {selectedTab === 0 && <About about={about} />}
        {selectedTab === 1 && <Projects about={about} />}
      </div>
    </div>
  );
};

export default ConnectProfileDetails;
