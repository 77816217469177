import { useParams } from "react-router-dom";
import ProjectDetailsContainer from "./ProjectDetailsContainer"; // Assuming your ProjectDetails component is in the same directory
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Avatar,
  Button,
  Modal,
  Paper,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  db
} from "../../../store/firebase/firebase";
import MeethubDrawer from '../../../components/shared/MeethubDrawer'; // Correct path
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";

import AppsIcon from "@material-ui/icons/Apps";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import Appbar from "../../../components/Appbar";
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ProjectDetails from "./ProjectDetails";
import { useStep } from "react-hooks-helper";
import ReactPagination from "react-paginate";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import ProductForm from "../../CreateProfile/components/ProductForm";
import AppBar from "../../../components/Appbar";

const drawerWidth = 150;


const useStyles = makeStyles((theme) => ({
  cardContent: {
    background: "#fff",
    boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
    padding: theme.spacing(3, 1),
    height: "100%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  showingResults: {
    marginTop: "35px",
    // fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    // lineHeight: "17px",
    color: "#485465",
  },
  paginationContainer: {
    listStyle: "none",
    display: "flex",
    marginTop: "35px",
    "& a": {
      border: "1px solid #ABB3BF",
      boxSizing: "border-box",
      borderRadius: "3px",
      padding: "10px 15px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      margin: "0px 2px",
      "&:hover": {
        background: "#180E40",
        color: "#fff",
      },
    },
  },
  paginationActive: {
    background: "#180E40",
    color: "#fff",
    border: "none",
  },
  disabledBtn: {
    color: "#ABB3BF",
  },
  title: {
    color: "#180E40",
    fontWeight: "bold",
    fontSize: 40,
    lineHeight: "50px",
  },
  modalStyle1: {
    position: "absolute",
    top: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  content: {
    // backgroundColor: "#E3E8EC",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  header: {
    textAlign: "center",
    marginTop: "4%",
    marginBottom: "4%",
  },
  paper: {
    marginBlock: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "30px",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icons: {
    minWidth: "40px",
  },
  categoryTag: {
    borderRadius: "8px",
    background: "#DAE7FF",
    padding: "8px 12px",
    display: "inline-block",
    color: "#1F66EC", // Lighter blue
    marginBottom: theme.spacing(2),
},
description: {
    fontSize: 18,
    lineHeight: "30px",
    color: "#485465", // Grayish color
},
}));

export default function MeethubProjects() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState(0);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const postsPerPage = 9;
  // const history = useHistory();

  const [responseData, setResponseData] = useState([]);
  const [fullResponse, setFullResponse] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [activateSearch, setActivateSearch] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const { userType } = useParams();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsCollectionRef = collection(db, "projects");
        const querySnapshot = await getDocs(projectsCollectionRef);
        if (querySnapshot.empty) {
          setEmpty(true);
        } else {
          const reportData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            imgUrl: doc.data().images && doc.data().images.length > 0
              ? doc.data().images[0].imageUrl
              : "",
            description: doc.data().description,
            city: doc.data().city,
            category: doc.data().category,
            country: doc.data().country,
            special: doc.data().special,
          }));
          setActivateSearch(false);
          setEmpty(false);
          setResponseData(reportData);
          // If you need fullData for some reason, populate it here
          // setFullResponse(fullData); 
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, [userType]);

  const offSet = currentPageNumber * postsPerPage;
  const currentPost = responseData.slice(offSet, offSet + postsPerPage);

  const totalPosts = responseData.length;
  const totalNumberOfPages = Math.ceil(totalPosts / postsPerPage);

  const paginate = ({ selected: selectedPage }) => {
    setCurrentPageNumber(selectedPage);
  };

  const steps = [{ id: "profile" }, { id: "details" }];

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const { next } = navigation;
  // const [userId, setUserId] = useState("");
  const [productId, setProductId] = useState("");

  const {
    auth: { isAuthenticated },
  } = useSelector((state) => state);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDismissModal = (value) => {
    setOpen(value);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedPage(index);
  };

  return (
    <>
      <Appbar />
      <div className={classes.root}>
        <CssBaseline />
        <MeethubDrawer open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} classes={classes} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            <Grid container spacing={2}>
              <Grid item container alignItems="center">
                <Grid item sm={9}>
                  <Typography variant="h1" className={classes.title}>
                    Explore Projects from other members
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <img
                    src="../../../assets/project.png"
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
                {isAuthenticated ? (
                  <Grid item sm={12}>
                    <Button variant="contained" onClick={handleOpen}>
                      <AddIcon />
                      New Project
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className={classes.modalStyle1}
                    >
                      <div>
                        <AppBar page="createProfile" />
                        <Grid
                          container
                          className={classes.content}
                          justifyContent="center"
                        >
                          <Grid item sm={12} md={8} lg={6}>
                            <Paper className={classes.paper}>
                              <ProductForm
                                handleDismissModal={handleDismissModal}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      </div>
                    </Modal>
                  </Grid>
                ) : null}
              </Grid>
              {currentPost
                .filter((item) => {
                  if (!textValue) return true;
                  if (
                    item.name.includes(textValue) ||
                    item.briefProfile.includes(textValue)
                  ) {
                    return true;
                  }
                  return false;
                })
                .filter((item) => {
                  if (!selectedCountry) return true;
                  if (item.country.includes(selectedCountry)) {
                    return true;
                  }
                  return false;
                })
                .map((individual, index) => (
                  <Grid item xs={12} sm={6} md={4} >
                    <Link to={`/project/${individual.id}`} style={{ textDecoration: 'none' }}>
                      <div className={classes.cardContent}>
                        <div>
                        <Typography
                              gutterBottom variant="h5"
                              className={classes.cardTitle}>
                              {individual.name}
                            </Typography>
                        </div>
                        <div>
                          <Typography variant="body2"
                              gutterButtom
                              style={{
                                borderRadius: "8px",
                                background: "#DAE7FF",
                                padding: "8px 12px",
                                alignItems: "center",
                                color: "#1F66EC",
                                width: "fit-content",
                              }}
                              className={classes.categoryTag}
                            > 
                            {individual.category}
                          </Typography>
                          <div>
                            {/* <LocationOnIcon fontSize="small" /> */}
                            <Typography gutterBottom className={classes.cardDesc}>
                              {individual.description?.length > 400 ? individual.description.substring(0, 400) + '...' : individual.description}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Grid>
                ))}
            </Grid>
            <Grid container spacing={5}>
              <Grid item>
                <div style={{ display: "flex" }}>
                  <Typography className={classes.showingResults}>
                    {totalPosts < postsPerPage
                      ? "Showing results: " +
                      (offSet + 1) +
                      "-" +
                      (offSet + totalPosts) +
                      " of " +
                      totalPosts
                      : "Showing results: " +
                      (offSet + 1 + " - " + (offSet + postsPerPage)) +
                      " of " +
                      totalPosts}
                  </Typography>

                  <ReactPagination
                    containerClassName={classes.paginationContainer}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={totalNumberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={paginate}
                    activeLinkClassName={classes.paginationActive}
                    disabledClassName={classes.disabledBtn}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </main>
      </div>
    </>
  );
};