import React, { useEffect, useState } from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { db } from "../../../store/firebase/firebase";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
const useStyles = makeStyles((theme) => ({
  card: {
    background: "#fff",
    boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
    padding: theme.spacing(2),
    height: "100%",
  },

  desc: {
    color: "rgba(132, 142, 156, 1)",
  },
  inline: {
    display: "flex",
  },
  inlineSpacer: {
    display: "flex",
    marginBlock: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(1),
    color: "#EA4C89",
  },
  title: {
    color: "#180E40",
    fontWeight: "bold",
  },
  img: {
    maxWidth: "100%",
  },
}));

const Projects = (props) => {
  const classes = useStyles();
  const { about } = props;
  console.log("about: ", about);

  return (
    <Grid container spacing={2}>
      {about && about.products.map((value, index) => {
        <Grid item sm={4} md={3}>
        <div className={classes.card}>
          <img src={value.profilePhoto} className={classes.img}  alt=""/>
          <Typography className={classes.title} gutterBottom>
            {value.name}
          </Typography>
          <Typography className={classes.desc}>
            {value.description}{"data "}
          </Typography>
        </div>
      </Grid>
      })}
    </Grid>
  );
};

export default Projects;
