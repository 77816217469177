import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  MenuItem,
  Typography,
  // Grid,
  // Paper,
  Divider,
} from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import ProductMediaUpload from "../../../components/ProductImageUpload";
import AddIcon from "@material-ui/icons/Add";
import validate from "validate.js";
import ImageList from "../../../components/ImageList";
import ProductList from "./ProductList";
// cuid is a simple library to generate unique IDs
import cuid from "cuid";
import { db, myFirebase, storageRef } from "../../../store/firebase/firebase";
import UpdateIcon from "@material-ui/icons/Update";
import ProgressLoader from "../../../components/Progressbar/ProgressLoader";
import swal from "sweetalert";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

const schema = {
  category: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  // industry: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 64,
  //   },
  // },
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  description: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  special: {
    presence: { allowEmpty: true, message: "is not required" },
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  steps: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(3),
    color: "#605A5A",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: "rgba(24, 14, 64, 1)",
    // marginBottom: theme.spacing(4),
  },
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    paddingInline: theme.spacing(4),
    backgroundColor: "rgba(24, 14, 64, 1)",
    color: "#fff",
    "&$disabled": {
      background: "#fff",
      color: "rgba(24, 14, 64, 1)",
      border: "1px solid rgba(24, 14, 64, 1)",
    },
  },
  disabled: {},
}));

// const categories = require('../../../utils/categories.json');
const industries = require("../../../utils/industries.json");

export default function ProductForm(props) {
  const classes = useStyles();

  const [images, setImages] = useState([]);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [products, setProducts] = useState([]);
  const userId = localStorage.getItem("user_id");
  // const [counter, setCounter] = React.useState(0);
  const account = localStorage.getItem("account");
  const [loading, setLoading] = useState(false);
  const dismissCallback = (value) => {
    props.handleDismissModal(value);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, []);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleUpload = (image) => {
    setLoading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `identification/${userId}/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      (error) => {
        console.error("Error uploading image:", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            console.log("url: ", url);
            setImagesUrl((prevState) => [
              ...prevState,
              { id: cuid(), imageUrl: url },
            ]);
            setLoading(false);
            const userDocRef = doc(db, "users", userId);
            getDoc(userDocRef)
              .then((docSnapshot) => {
                if (docSnapshot.exists()) {
                  updateDoc(userDocRef, {
                    image_path: url,
                  });
                } else {
                  console.log("User document does not exist");
                }
              })
              .catch((error) => {
                console.error("Error updating user document:", error);
              });
          })
          .catch((error) => {
            console.error("Error getting download URL:", error);
          });
      }
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Loop through accepted files
    acceptedFiles.map((file) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        setImages((prevState) => [
          ...prevState,
          { id: cuid(), src: e.target.result },
        ]);
      };
      // Read the file as Data URL (since we accept only images)
      handleUpload(file);
      //console.log(file);
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const handleSubmit = async () => {
    try {
      const projectsCollectionRef = collection(db, "projects");
      await addDoc(projectsCollectionRef, {
        account: account ?? "individual",
        userId,
        category: formState.values.category,
        name: formState.values.name,
        description: formState.values.description,
        special: formState.values.special ?? "",
        images: imagesUrl,
      });
      swal("Project Added Successfully");
      dismissCallback(false);
    } catch (error) {
      swal("Error Adding Project");
      dismissCallback(false);
      console.error("Error adding project:", error);
    }
  };

  const handleAddProduct = (event) => {
    event.preventDefault();
    setProducts((prevState) => [
      ...prevState,
      {
        id: cuid(),
        category: formState.values.category,
        // industry: formState.values.industry,
        name: formState.values.name,
        description: formState.values.description,
        special: formState.values.special,
        images: imagesUrl,
      },
    ]);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        category: "",
        // industry: "",
        name: "",
        description: "",
        special: "",
        images: [],
      },
    }));
    setImagesUrl([]);
    setImages([]);
  };

  const handleRemoveInput = (i) => {
    const values = [...products];
    // console.log(values);
    values.splice(i, 1);
    setProducts(values);
  };

  const [showUpdate, setShowUpdate] = useState(false);
  const [productPosition, setProductPosition] = useState(0);

  const handleEdit = (product, index) => {
    console.log("editIndex", index);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        category: product.category,
        // industry: product.industry,
        name: product.name,
        description: product.description,
        special: product.description,
        images: product.images,
      },
    }));
    setProductPosition(index);
    setShowUpdate(true);
  };

  const handleUpdate = (event, index) => {
    event.preventDefault();
    const values = [...products];
    // console.log(values);
    values.splice(index, 1);
    const newProduct = {
      id: cuid(),
      category: formState.values.category,
      // industry: formState.values.industry,
      name: formState.values.name,
      description: formState.values.description,
      special: formState.values.special,
      images: imagesUrl,
    };
    const newProducts = [...values];
    newProducts.splice(index, 0, newProduct);

    setProducts(newProducts);

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        category: "",
        // industry: "",
        name: "",
        description: "",
        special: "",
        images: [],
      },
    }));
    setImagesUrl([]);
    setImages([]);

    setShowUpdate(false);
  };

  return (
    <div>
      <Typography gutterBottom className={classes.title}>
        Add Project
      </Typography>
      <Divider style={{ marginBottom: "16px" }} />
      {products.length !== 0 ? (
        <ProductList
          products={products}
          handleRemoveInput={handleRemoveInput}
          handleEdit={handleEdit}
        />
      ) : null}

      <Typography gutterBottom className={classes.label}>
        Category
      </Typography>
      <TextField
        name="category"
        onChange={handleChange}
        value={formState.values.category}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
        select
      >
        {industries.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </TextField>

      <Typography gutterBottom className={classes.label}>
        Name
      </Typography>
      <TextField
        name="name"
        onChange={handleChange}
        value={formState.values.name}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
      />

      <Typography gutterBottom className={classes.label}>
        Description
      </Typography>
      <TextField
        name="description"
        onChange={handleChange}
        value={formState.values.description}
        className={classes.field}
        fullWidth
        multiline
        variant="outlined"
        size="small"
        rows={10}
      />

      <Typography gutterBottom className={classes.label}>
        Special features
      </Typography>
      <TextField
        name="special"
        onChange={handleChange}
        value={formState.values.special}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
      />
      <div style={{ marginBottom: 30 }}>
        <Typography gutterBottom className={classes.label}>
          Add files related to this project (.jpg, .png, .mp4) Max 5
        </Typography>

        {loading ? (
          <ProgressLoader />
        ) : (
          <ProductMediaUpload onDrop={onDrop} accept={"image/*"} />
        )}
        <ImageList images={images} />
      </div>

      <div>
        <Button
          variant="text"
          style={{ color: "rgba(24, 14, 64, 1)", marginBottom: 40 }}
          onClick={(event) => handleAddProduct(event)}
        >
          <AddIcon />
          Add more products
        </Button>
        {showUpdate ? (
          <Button
            variant="text"
            style={{ color: "#FF0000", marginBottom: 40 }}
            onClick={(event) => handleUpdate(event, productPosition)}
          >
            <UpdateIcon />
            Update Product
          </Button>
        ) : null}
      </div>
      <Button
        // className={classes.button}
        // classes={{
        //   root: classes.button,
        //   disabled: classes.disabled,
        // }}
        onClick={() => dismissCallback(false)}
        variant="outlined"
        style={{ marginRight: "15px" }}
      >
        Cancel
      </Button>

      <Button
        // className={classes.button}
        classes={{
          root: classes.button,
          disabled: classes.disabled,
        }}
        onClick={handleSubmit}
        variant="contained"
      >
        Submit
      </Button>
    </div>
  );
}
