
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import AppsIcon from "@material-ui/icons/Apps";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { Link } from "react-router-dom";

function
MeethubDrawer({ open, handleDrawerClose, handleDrawerOpen, classes }) {
    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar} />

            {open ? (
                <IconButton onClick={handleDrawerClose} style={{ borderRadius: 0 }}>
                    <ChevronLeftIcon />
                </IconButton>
            ) : (
                <IconButton onClick={handleDrawerOpen} style={{ borderRadius: 0 }}>
                    <ChevronRightIcon />
                </IconButton>
            )}

            <List>
                <Link to="/connect" style={{ textDecoration: "none", color: "inherit" }}>
                    <ListItem button key="individual">
                        <ListItemIcon className={classes.icons}>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Individuals" />
                    </ListItem>
                </Link>

                <Link to="/connect" style={{ textDecoration: "none", color: "inherit" }}>
                    <ListItem button key="businesses">
                        <ListItemIcon className={classes.icons}>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary="Hubs & DIHs" />
                    </ListItem>
                </Link>

                <Link to="/projects" style={{ textDecoration: "none", color: "inherit" }}>
                    <ListItem button key="projects">
                        <ListItemIcon className={classes.icons}>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Projects" />
                    </ListItem>
                </Link>
            </List>
        </Drawer>
    );
}

export default MeethubDrawer;
