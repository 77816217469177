import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Avatar,
  Button,
  Modal,
  Paper,
} from "@material-ui/core";
// import LocationOnIcon from "@material-ui/icons/LocationOn";
import { db } from "../../../store/firebase/firebase";
import { useHistory } from "react-router-dom";
import ProjectDetails from "./ProjectDetails";
import { useStep } from "react-hooks-helper";
import ReactPagination from "react-paginate";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import ProductForm from "../../CreateProfile/components/ProductForm";
import AppBar from "../../../components/Appbar";
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    background: "#fff",
    boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
    padding: theme.spacing(3, 1),
    height: "100%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  showingResults: {
    marginTop: "35px",
    // fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    // lineHeight: "17px",
    color: "#485465",
  },
  paginationContainer: {
    listStyle: "none",
    display: "flex",
    marginTop: "35px",
    "& a": {
      border: "1px solid #ABB3BF",
      boxSizing: "border-box",
      borderRadius: "3px",
      padding: "10px 15px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      margin: "0px 2px",
      "&:hover": {
        background: "#180E40",
        color: "#fff",
      },
    },
  },
  paginationActive: {
    background: "#180E40",
    color: "#fff",
    border: "none",
  },
  disabledBtn: {
    color: "#ABB3BF",
  },
  title: {
    color: "#180E40",
    fontWeight: "bold",
    fontSize: 40,
    lineHeight: "50px",
  },
  modalStyle1: {
    position: "absolute",
    top: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  content: {
    // backgroundColor: "#E3E8EC",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  header: {
    textAlign: "center",
    marginTop: "4%",
    marginBottom: "4%",
  },
  paper: {
    marginBlock: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "30px",
  },
}));

const AllProjects = (props) => {
  const classes = useStyles();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const postsPerPage = 24;
  // const history = useHistory();

  const [responseData, setResponseData] = useState([]);
  const [fullResponse, setFullResponse] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [activateSearch, setActivateSearch] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsCollectionRef = collection(db, "projects");
        const querySnapshot = await getDocs(projectsCollectionRef);
        if (querySnapshot.empty) {
          setEmpty(true);
        } else {
          const reportData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            imgUrl: doc.data().images && doc.data().images.length > 0
              ? doc.data().images[0].imageUrl
              : "",
            description: doc.data().description,
            city: doc.data().city,
            country: doc.data().country,
          }));
          setActivateSearch(false);
          setEmpty(false);
          setResponseData(reportData);
          // If you need fullData for some reason, populate it here
          // setFullResponse(fullData); 
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, [props.userType]);

  const offSet = currentPageNumber * postsPerPage;
  const currentPost = responseData.slice(offSet, offSet + postsPerPage);

  const totalPosts = responseData.length;
  const totalNumberOfPages = Math.ceil(totalPosts / postsPerPage);

  const paginate = ({ selected: selectedPage }) => {
    setCurrentPageNumber(selectedPage);
  };

  const steps = [{ id: "profile" }, { id: "details" }];

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const { next } = navigation;
  // const [userId, setUserId] = useState("");
  const [productId, setProductId] = useState("");

  const handleCallbackForId = (value) => {
    setProductId(value);
    next();
  };

  const {
    auth: { isAuthenticated },
  } = useSelector((state) => state);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDismissModal = (value) => {
    setOpen(value);
  };

  const renderSwitch = (id) => {
    switch (id) {
      case "profile":
        return (
          <>
            <Grid container spacing={2}>
              <Grid item container alignItems="center">
                <Grid item sm={9}>
                  <Typography variant="h1" className={classes.title}>
                    Explore Projects from other members
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <img
                    src="../../../assets/project.png"
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
                {isAuthenticated ? (
                  <Grid item sm={12}>
                    <Button variant="contained" onClick={handleOpen}>
                      <AddIcon />
                      New Project
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className={classes.modalStyle1}
                    >
                      <div>
                        <AppBar page="createProfile" />
                        <Grid
                          container
                          className={classes.content}
                          justifyContent="center"
                        >
                          <Grid item sm={12} md={8} lg={6}>
                            <Paper className={classes.paper}>
                              <ProductForm
                                handleDismissModal={handleDismissModal}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      </div>
                    </Modal>
                  </Grid>
                ) : null}
              </Grid>
              {currentPost
                .filter((item) => {
                  if (!textValue) return true;
                  if (
                    item.name.includes(textValue) ||
                    item.briefProfile.includes(textValue)
                  ) {
                    return true;
                  }
                  return false;
                })
                .filter((item) => {
                  if (!selectedCountry) return true;
                  if (item.country.includes(selectedCountry)) {
                    return true;
                  }
                  return false;
                })
                .map((individual, index) => (
                  <Grid
                    item
                    sm={4}
                    md={3}
                    
                  > 
                      <Link to={`/project/${individual.id}`} style={{ textDecoration: 'none' }}>
                    <div className={classes.cardContent}>
                      <div>
                        <Avatar
                          src={individual.imgUrl}
                          className={classes.avatar}
                        />
                      </div>
                      <div
                        style={{
                          paddingBlock: "20px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography gutterBottom variant="h5">
                          {individual.name}
                        </Typography>
                        <div>
                          {/* <LocationOnIcon fontSize="small" /> */}
                          <Typography>
                            {individual.description}
                            {/* {individual.city}, {individual.country} */}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </Grid>
                ))}
            </Grid>
            <Grid container spacing={5}>
              <Grid item>
                <div style={{ display: "flex" }}>
                  <Typography className={classes.showingResults}>
                    {totalPosts < postsPerPage
                      ? "Showing results: " +
                      (offSet + 1) +
                      "-" +
                      (offSet + totalPosts) +
                      " of " +
                      totalPosts
                      : "Showing results: " +
                      (offSet + 1 + " - " + (offSet + postsPerPage)) +
                      " of " +
                      totalPosts}
                  </Typography>

                  <ReactPagination
                    containerClassName={classes.paginationContainer}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={totalNumberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={paginate}
                    activeLinkClassName={classes.paginationActive}
                    disabledClassName={classes.disabledBtn}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        );
      case "details":
        return <ProjectDetails productId={productId} />;
      default:
        break;
    }
  };

  return <>{renderSwitch(id)}</>;
};

export default AllProjects;
