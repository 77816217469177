import React, { useEffect, useState } from "react";
import { db } from "../../../store/firebase/firebase";
import { collection, getDocs, doc } from "firebase/firestore";


const ProjectDetails = (props) => {
  const [product, setProduct] = useState();
  // State to track selected image
  const [selectedImage, setSelectedImage] = useState(product.images[0]);

  const productId = props.productId;

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectDocRef = doc(db, "projects", productId);
        const docSnapshot = await getDocs(projectDocRef);
        if (docSnapshot.exists()) {
          const projectData = docSnapshot.data();
          setProduct(projectData);
          setSelectedImage(projectData.images && projectData.images.length > 0 ? projectData.images[0] : null); 
        } else {
          console.log("Snapshot does not exist");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };
    if (productId) {
      fetchProject();
    }
  }, [productId]);
  if (!product) {
    return <div>Loading...</div>; // Or a more informative loading state
  }
  return (
    <div className="project-details-container">
      {/* Left side with images */}
      <div className="left-side">
        <div className="big-image">
          {selectedImage && <img src={selectedImage} alt="Selected Project" />}
        </div>
        <div className="image-thumbnails">
          {product.images && product.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Project ${index}`}
              onClick={() => setSelectedImage(image)}
            />
          ))}
        </div>
      </div>
      {/* Right side with project details */}
      <div className="right-side">
        <h2>{product.name}</h2>
        <p>{product.description}</p>
        <p>{product.category}</p>
      </div>
    </div>
  );
};

export default ProjectDetails;
