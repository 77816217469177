import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "../components/Appbar";
import Footer from "../components/Footer";
import {
  Button,
  Grid,
  Typography,
  Container,
  Paper,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useHistory, Link } from "react-router-dom";
import { db } from "../store/firebase/firebase";
import { useStep } from "react-hooks-helper";
import ConnectProfileDetails from "./Connect/components/ConnectProfileDetails";
// import { ReactComponent as Challenges } from "../svgs/challenges.svg";
import Marquee from "react-fast-marquee";
import { doc, getDocs } from "firebase/firestore";
import { getFirestore, collection } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  hero: {
    textAlign: "center",
    margin: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
  heroTitle: {
    fontSize: 40,
    lineHeight: "50px",
    fontWeight: 700,
    // margin: theme.spacing(5, 0, 2),
  },
  heroSubText: {
    color: "#848E9C",
  },
  content: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  center: {
    textAlign: "center",
  },
  feature: {
    marginBlock: "5%",
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBlock: "10%",
      padding: theme.spacing(0),
    },
  },
  imageFit: {
    maxWidth: "100%",
  },
  bubble1: {
    backgroundColor: "#F9EAEA",
    height: 50,
    width: "80%",
    borderRadius: "100px 140px 0px 100px",
    margin: "10px auto",
    [theme.breakpoints.down("xs")]: {
      height: 30,
    },
  },
  bubble2: {
    backgroundColor: "#C4DFE6",
    height: 50,
    width: "80%",
    borderRadius: "100px 140px 0px 100px",
    margin: "0 auto 10px",
    [theme.breakpoints.down("xs")]: {
      height: 30,
    },
  },
  featureColumn2: {
    textAlign: "center",
    marginTop: "-50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-30px",
    },
  },
  featureColumn4: {
    textAlign: "center",
    marginTop: "-20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  featureParagraph: {
    textAlign: "center",
    color: "#252D39",
    lineHeight: "28px",
  },
  scoreCard: {
    boxShadow: "0px 7px 20px rgba(0, 0, 0, 0.07)",
    marginTop: 50,
    paddingBlock: 30,
  },
  cardContent: {
    textAlign: "center",
  },
  scoreTitle: {
    color: "#180E40",
    fontSize: 32,
    marginBottom: 20,
    fontWeight: "bold",
  },
  scoreSub: {
    color: "#848E9C",
    fontSize: 16,
  },
  communitySection: {
    marginBlock: "8%",
  },
  paragraph: {
    color: "#252D39",
    lineHeight: "28px",
  },
  communityBtn: {
    background: "#180E40",
    borderRadius: "24px 24px 24px 0px",
    color: "#fff",
    padding: "6px 30px",
    marginTop: 20,
  },
  challengePaper: {
    padding: 10,
    height: "100%",
  },
  subTitle: {
    color: "#180E40",
    fontSize: 28,
    lineHeight: "20px",
    // marginBottom: 20,
    fontWeight: "bold",
  },
  cardTitle: {
    color: "#252D39",
    fontWeight: "bold",
  },
  cardDesc: {
    color: "#252D39",
  },
  dateDiv: {
    display: "flex",
    alignItems: "center",
    // paddingBottom: 10,
  },
  calendar: {
    color: "#EA4C89",
    paddingRight: 5,
  },
  date: {
    color: "#848E9C",
    fontSize: 13,
  },
  exploreCard: {
    padding: 10,
    backgroundColor: "#180E40",
    textAlign: "center",
    marginBottom: 10,
  },
  exploreBtn: {
    margin: "20px 0",
    background: "#fff",
    borderRadius: "24px 24px 0px 24px",
    color: "#180E40",
    fontSize: 12,
  },
  ctaContainer: {
    margin: "60px auto",
    backgroundColor: "#180E40",
    padding: "45px",
    borderRadius: 10,
  },
  ctaTitle: {
    textAlign: "center",
    color: "#EA4C89",
    fontSize: 24,
    fontWeight: "bold",
  },
  ctaSub: { textAlign: "center", color: "#fff" },
  ctaBtn: {
    background: "#fff",
    borderRadius: "24px",
    color: "#180E40",
    padding: "6px 30px",
    marginTop: 20,
  },
  spacer: {
    marginBottom: "20px",
  },
}));
const ourCommunityImages = [
  {
    id: 1,
    image: "./assets/ourCommunityImage1.jpg",
  },
  {
    id: 2,
    image: "./assets/ourCommunityImage2.jpg",
  },
  {
    id: 3,
    image: "./assets/ourCommunityImage3.jpg",
  },
  {
    id: 4,
    image: "./assets/ourCommunityImage4.jpg",
  },
  {
    id: 5,
    image: "./assets/ourCommunityImage5.jpg",
  },
  {
    id: 6,
    image: "./assets/ourCommunityImage6.jpg",
  },
];
function Home({ dispatch, isAuthenticated, loginError }) {
  const classes = useStyles();
  // const [professionals, setProfessionals] = useState([]);
  // const [businesses, setBusinesses] = useState([]);
  // const [empty, setEmpty] = useState(false);
  const [animate, setAnimate] = useState("Connect");
  const [projects, setProjects] = useState([]);
  const [businessProfile, setBusinessProfile] = useState([]);
  const [userId, setUserId] = useState("");
  const steps = [{ id: "profile" }, { id: "details" }];

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const { next } = navigation;

  const history = useHistory(); // Call useHistory here
  const handleProjectClick = (item) => { // Pass item as an argument
    console.log('Project clicked:', item);
    //stopPropagation();
    history.push('/connect', { state: { projectDetails: item } });
  };

  useEffect(() => {
    let x = 0;
    const interval = setInterval(() => {
      x % 2 === 0 ? setAnimate("Learn") : setAnimate("Connect");

      x++;
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Fetch users
    const fetchUsers =
      async () => {
        const usersCollectionRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        let fullData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          imgUrl: doc.data().profilePhoto,
          briefProfile: doc.data().bio,
          city: doc.data().city,
          country: doc.data().country,
          accountType: doc.data().account,
        }));
        setBusinessProfile(fullData.filter((item) => item.accountType === "business"));
      };
    // Fetch projects
    const fetchProjects = async () => {
      const projectsCollectionRef = collection(db, "projects");
      const projectsSnapshot = await getDocs(projectsCollectionRef);
      let fullData = projectsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        imgUrl: doc.data().images,
        description: doc.data().description,
        category: doc.data().category,
        special: doc.data().special,
        accountType: doc.data().account,
      }));
      setProjects(fullData);
    };
    fetchUsers().catch((error) => console.log(error));
    fetchProjects().catch((error) => console.log(error));
  }, []);

  const handleCallbackForId = (value) => {
    setUserId(value);
    next();
  };

  const renderBusinessProfile = (id) => {
    switch (id) {
      case "profile":
        return (
          <>
            <Container>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={8}
                style={{ margin: "40px 0" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{
                      borderRadius: "30px",
                      background: "#FFDEEB",
                      width: "fit-content",
                      padding: "8px 24px",
                    }}
                  >
                    Join 200+ IoT professionals
                  </Typography>
                  <Typography gutterBottom className={classes.heroTitle}>
                    <span style={{ color: "#EA4C89" }}>{animate}</span> with IoT
                    Professionals
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h4"
                    className={classes.heroSubText}
                  >
                    Build your network with IoT Professionals while learning and
                    sharing innovative tips in the industry.
                  </Typography>
                  <Button
                    variant="outlined"
                    component={Link}
                    to="/connect"
                    style={{ borderRadius: "50px" }}
                  >
                    Connect
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <img
                    src="../../assets/heroImage.svg"
                    alt=""
                    className={classes.imageFit}
                    style={{
                      aspectRatio: "16/9",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    textAlign: "center",
                    // padding:"80px",
                  }}
                >
                  <Typography variant="h3" className={classes.subTitle}>
                    Meethub Community
                  </Typography>
                  <Box>
                    <Typography variant="body1">
                      Meethub is a community fueled by the HUBiquitous Project,
                      bringing together innovators in the IoT space. The Meethub
                      platform provides
                    </Typography>{" "}
                    <Typography variant="body1">
                      a conducive environment to encourage networking and
                      learning between professionals, enthusiasts, individuals
                      and businesses.
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth="false"
                    component={Link}
                    to="/connect"
                    style={{ borderRadius: "30px", width: "fit-content" }}
                  >
                    Join the community
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Marquee
              // component={Marquee}
              pauseOnHover
              style={{
                margin: "40px 0",
              }}
            >
              {ourCommunityImages.map((image) => (
                <img
                  key={image.id}
                  src={image.image}
                  alt={`community ${image.id}`}
                  // className={classes.imageFit}
                  style={{
                    width: "300px",
                    objectFit: "cover",
                    objectPosition: "center",
                    aspectRatio: "16/9",
                    borderRadius: "8%",
                    marginRight: "10px",
                  }}
                />
              ))}
            </Marquee>
            <Container>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    textAlign: "center",
                    marginBottom: "30px",
                    // padding:"80px",
                  }}
                >
                  <Typography variant="h3" className={classes.subTitle}>
                    Exploring Meethub's Diverse Community
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.cardDesc}
                  >
                    Our platform welcomes Tech hubs, Makerspaces and Fablabs,
                    working together to shape the future of IoT and Big Data for
                    growth in Africa.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 40 }}
                >
                  <Grid item xs={12} md={5}>
                    <Grid item xs={12}>
                      <img
                        src="../../assets/code.png"
                        alt=""
                        style={{ width: "30px", aspectRatio: 1 }}
                      />
                      <Typography gutterBottom className={classes.cardTitle}>
                        Tech Hubs
                      </Typography>
                      <Typography gutterBottom className={classes.cardDesc}>
                        Connect and collaborate with leading tech hubs driving
                        innovation and technological advancements in Africa. Our
                        community includes visionary hubs at the forefront of
                        driving technological innovation across the continent.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <img
                        src="../../assets/reading.png"
                        alt=""
                        style={{ width: "30px", aspectRatio: 1 }}
                      />
                      <Typography gutterBottom className={classes.cardTitle}>
                        Makerspaces
                      </Typography>
                      <Typography gutterBottom className={classes.cardDesc}>
                        Discover makerspaces in our community, where hands-on
                        experimentation transforms innovative ideas into
                        prototypes. These dynamic environments foster creativity
                        and empower individuals and teams by providing access to
                        state-of-the-art tools and equipment.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <img
                        src="../../assets/puzzle-travel.png"
                        alt=""
                        style={{ width: "30px", aspectRatio: 1 }}
                      />
                      <Typography gutterBottom className={classes.cardTitle}>
                        Fablabs
                      </Typography>
                      <Typography gutterBottom className={classes.cardDesc}>
                        Collaborate with fablabs in our community and redefine
                        possibilities. These labs offer cutting-edge resources
                        and support necessary to rapidly prototype and develop
                        products, revolutionizing the way ideas come to life.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <img
                      src="../../assets/connectBus.jpg"
                      alt=""
                      style={{
                        width: "90%",
                        objectFit: "cover",
                        objectPosition: "center",
                        aspectRatio: "16/12",
                        borderRadius: "30px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Box
    display="flex"
    justifyContent={"space-between"}
    alignItems="center"
    style={{ margin: "50px" }}
  >
    <Typography variant="h3" className={classes.subTitle}>
      Upcoming Events
    </Typography>
    <Button
      variant="outlined"
      component={Link}
      to="/connect"
      style={{ borderRadius: "50px" }}
    >
      See all
    </Button>
  </Box>
  <Grid container spacing={5} justifyContent="space-between" px={10}>
    <Grid item sm={3}>
      <Paper
        className={classes.challengePaper}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 5,
        }}
      >
        <Typography
          variant="body2"
          gutterButtom
          style={{
            borderRadius: "8px",
            background: "#DAE7FF",
            padding: "8px 12px",
            color: "#1F66EC",
            width: "fit-content",
          }}
        >
          Virtual Event
        </Typography>

        <Typography gutterButtom className={classes.cardTitle}>
          Build the nation
        </Typography>
        <Typography gutterBottom className={classes.cardDesc}>
          Tips for transitioning into a new role or company. Tips for
          transitioning int
        </Typography>
        <div
          className={classes.dateDiv}
          style={{
            justifyContent: "center",
          }}
        >
          <Typography className={classes.date}>
            13 November, 2023
          </Typography>
        </div>
      </Paper>
    </Grid>
    <Grid item sm={3}>
      <Paper
        className={classes.challengePaper}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 5,
        }}
      >
        <Typography
          variant="body2"
          gutterButtom
          style={{
            borderRadius: "8px",
            background: "#DAE7FF",
            padding: "8px 12px",
            color: "#1F66EC",
            width: "fit-content",
          }}
        >
          Virtual Event
        </Typography>

        <Typography gutterButtom className={classes.cardTitle}>
          Build the nation
        </Typography>
        <Typography gutterBottom className={classes.cardDesc}>
          Tips for transitioning into a new role or company. Tips for
          transitioning int
        </Typography>
        <div
          className={classes.dateDiv}
          style={{
            justifyContent: "center",
          }}
        >
          <Typography className={classes.date}>
            13 November, 2023
          </Typography>
        </div>
      </Paper>
    </Grid>
    <Grid item sm={3}>
      <Paper
        className={classes.challengePaper}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 5,
        }}
      >
        <Typography
          variant="body2"
          gutterButtom
          style={{
            borderRadius: "8px",
            background: "#DAE7FF",
            padding: "8px 12px",
            color: "#1F66EC",
            width: "fit-content",
          }}
        >
          Virtual Event
        </Typography>

        <Typography gutterButtom className={classes.cardTitle}>
          Build the nation
        </Typography>
        <Typography gutterBottom className={classes.cardDesc}>
          Tips for transitioning into a new role or company. Tips for
          transitioning int
        </Typography>
        <div
          className={classes.dateDiv}
          style={{
            justifyContent: "center",
          }}
        >
          <Typography className={classes.date}>
            13 November, 2023
          </Typography>
        </div>
      </Paper>
    </Grid>
  </Grid> */}
              {/* Conditionally render the Projects section */}
              {projects.length > 0 && (
                <>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                    style={{ margin: "50px" }}
                  >
                    <Typography variant="h3" className={classes.subTitle}>
                      Featured Projects
                    </Typography>
                    <Button
                      variant="outlined"
                      component={Link}
                      to="/connect"
                      style={{ borderRadius: "50px" }}
                    >
                      See all
                    </Button>
                  </Box>
                  <Grid
                    container
                    spacing={5}
                    justifyContent="space-between"
                    px={10}
                  >
                    {projects.slice(0, 6).map((item) => (
                      <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <Link to={`/project/${item.id}`} style={{ textDecoration: 'none' }}> {/* Wrap the Paper component with Link */}
                          <Paper
                            className={classes.challengePaper}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "16px",
                              gap: 3,
                            }}
                          // onClick={() => handleProjectClick(item)}
                          >
                            <Typography
                              variant="body2"
                              gutterButtom
                              style={{
                                borderRadius: "8px",
                                background: "#DAE7FF",
                                padding: "8px 12px",
                                alignItems: "center",
                                color: "#1F66EC",
                                width: "fit-content",
                              }}
                            >
                              {item.category}
                            </Typography>
                            <Typography variant="h5" gutterButtom className={classes.cardTitle}>
                              {item.name}
                            </Typography>
                            <Typography gutterBottom className={classes.cardDesc}>
                              {item.special?.length > 200 ? item.special.substring(0, 200) + '...' : item.special}
                            </Typography>
                          </Paper>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {/* Conditionally render the DIHs and Techhubs section */}
              {businessProfile.length > 0 && (
                <Container>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                    style={{ margin: "50px" }}
                  >
                    <Typography variant="h3" className={classes.subTitle}>
                      DIHs and Techhubs
                    </Typography>
                    <Button
                      variant="outlined"
                      component={Link}
                      to="/connect"
                      style={{ borderRadius: "50px" }}
                    >
                      See all
                    </Button>
                  </Box>
                  <Grid
                    container
                    spacing={5}
                    justifyContent="space-between"
                    px={10}
                  >
                    {businessProfile.slice(0, 3).map((item) => {
                      return (
                        <Grid item sm={3}>
                          <Paper
                            className={classes.challengePaper}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              gap: 5,
                            }}
                            onClick={() => handleCallbackForId(item.id)}
                          >
                            <Typography
                              variant="body2"
                              gutterButtom
                              style={{
                                borderRadius: "8px",
                                background: "#DAE7FF",
                                padding: "8px 12px",
                                color: "#1F66EC",
                                width: "fit-content",
                              }}
                            >
                              {item.name}
                            </Typography>

                            <Typography gutterButtom className={classes.cardTitle}>
                              {item.city}
                            </Typography>
                            <Typography gutterBottom className={classes.cardDesc}>
                              {item.briefProfile}
                            </Typography>
                            <div
                              className={classes.dateDiv}
                              style={{
                                justifyContent: "center",
                              }}
                            >
                              <Typography className={classes.date}>
                                {item.country}
                              </Typography>
                            </div>
                          </Paper>
                        </Grid>
                      );
                    })}

                  </Grid>
                </Container>
              )}
            </Container>
            <Container maxWidth="lg" className={classes.ctaContainer}>
              <Typography gutterBottom className={classes.ctaTitle}>
                Explore technical topics and other disciplines across 100+ Q&A
              </Typography>

              <Container maxWidth="sm" className={classes.center}>
                <Typography className={classes.ctaSub}>
                  A great way to begin networking is to have a passion geared
                  towards knowing what others are working on. So ask questions
                  and share ideas. Begin today and be part of our growing
                  community of disruptive change-makers.
                </Typography>

                <Button
                  variant="contained"
                  component={Link}
                  to="/connect"
                  className={classes.ctaBtn}
                >
                  Join the Community
                </Button>
              </Container>
            </Container>

            <Footer />
          </>
        );
      case "details":
        return (
          <ConnectProfileDetails userId={userId} navigation={navigation} />
        );

      default:
        break;
    }
  };

  return (
    <div>
      <AppBar isAuthenticated={isAuthenticated} />
      {renderBusinessProfile(id)}
    </div>
  );
}

Home.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(Home));
// useEffect(() => {
//   db.collection("users")
//     .get()
//     .then((reports) => {
//       let reportData = [];
//       let businessData = [];
//       if (reports.empty) {
//         setEmpty(true);
//       } else {
//         reports.forEach((doc) => {
//           if (doc.data().account === "individual") {
//             reportData.push({
//               id: doc.id ? doc.id : null,
//               name: doc.data().name,
//               email: doc.data().email,
//               city: doc.data().city,
//               imgUrl: doc.data().profilePhoto,
//               briefProfile: doc.data().bio,
//               country: doc.data().country,
//             });
//           } else {
//             businessData.push({
//               id: doc.id ? doc.id : null,
//               imgUrl: doc.data().profilePhoto,
//             });
//           }

//           return reportData;
//         });
//         console.log(reportData);
//         setEmpty(false);
//         setProfessionals(reportData);
//         setBusinesses(businessData);
//       }
//     })
//     .catch((error) => console.log(error));
// }, []);
