import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { db, myFirebase, storageRef } from "../store/firebase/firebase";
import ProgressLoader from "../components/Progressbar/ProgressLoader";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore methods

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  upload: {
    border: "1px solid #ABB3BF",
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  preview: {
    borderRadius: 15,
    background: "rgba(163, 163, 163, 0.21)",
    height: 165,
    width: 155,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  imagebox: {
    height: 165,
    width: 155,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
}));

export default function ProfileUpload(props) {
  const classes = useStyles();
  const userId = localStorage.getItem("user_id");
  const [progress, setProgress] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("userId: ", userId);

  const handleUpload = (e) => {
    setLoading(true);
    const { files } = e.target;
    if (!files.length) {
      return;
    }
    const image = files[0];
    //console.log("Image ", image);
    const storage = myFirebase.storage();

    const path = `identification/${userId}/${image.name}`;
    const image_path_child = `${userId}/${image.name}`;

    const uploadTask = storageRef.ref(path).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("identification")
          .child(image_path_child)
          .getDownloadURL()
          .then((url) => {
            setImageUrl(url);
            props.profileCallback(url);
            setLoading(false);
            const usersCollectionRef = collection(db, "users"); // Get collection reference
            const userDocRef = doc(usersCollectionRef, userId); // Get document reference

            getDoc(userDocRef)
              .then((docSnapshot) => {
                if (docSnapshot.exists()) {
                  updateDoc(userDocRef, {
                    image_path: url,
                  });
                } else {
                  console.log("Snapshot does not exist");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
      }
    );
  };

  return (
    <div>
      <div className={classes.field}>
        <input
          accept="image/*"
          className={classes.upload}
          id="contained-button-file"
          type="file"
          onChange={handleUpload}
        />
      </div>

      <Typography gutterBottom className={classes.label}>
        Profile photo preview
      </Typography>

      {loading ? (
        <ProgressLoader />
      ) : (
        <div>
          {imageUrl ? (
            <div className={classes.imagebox}>
              <img
                src={imageUrl}
                alt="profile"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
          ) : (
            <div className={classes.preview}>
              <CameraAltOutlinedIcon
                style={{ fontSize: 50, color: "#A3A3A3" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
