export const calculatePercentageCompleted = (profile) => {
    if (!profile) return 0;
    let requiredFields;
    if (profile.account === "Business") {
      requiredFields = [
        "accountManagerName",
        "bio",
        "country",
        "city",
        "email",
        "linkedIn",
        "name",
        "numberOfEmployees",
        "profileImage",
        "twitter",
        "website",
      ];
    } else if (profile.account === "Personal") {
      requiredFields = [
        "bio",
        "country",
        "city",
        "email",
        "firstName",
        "lastName",
        "linkedIn",
        "profileImage",
        "twitter",
        "website",
      ];
    }
  
    const totalRequiredFields = requiredFields.length;
    let completedFields = 0;
  
    requiredFields.forEach((field) => {
      if (profile[field]) {
        completedFields++;
      }
    });
  
    return (completedFields / totalRequiredFields) * 100;
  };
  