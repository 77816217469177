import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flag: {
    height: 12,
    width: 15,
    marginRight: 15,
  },
}));

export default function AfricanCountries(props) {
  const classes = useStyles();

  const chosenCountry = props.chosenCountry;
  const [country, setCountry] = useState(props.value);

  const handleChange = (event) => {
    chosenCountry(event.target.value);
    setCountry(event.target.value);
  }

  const countries = require("../utils/africa.json");
  return (
    <TextField
      className={classes.field}
      fullWidth
      variant="outlined"
      value={country}
      onChange={handleChange}
      size="small"
      select
    >
      {countries.map((nation, i) => (
        <MenuItem value={nation.name} key={i}>
          <img src={nation.flag} alt={nation.name} className={classes.flag} />
          {nation.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
