import { } from "../actions";
import {
  ALL_QUESTIONS,
  USER_QUESTIONS,
  ALL_COMMENTS,
  QUESTIONS_COMMENTS,
  REPLY,
  SHARE
} from "../actions/actionTypes";

let initialState = {
  userQuestions: [],
  allQuestions: [],
  allComments: [],
  questionComment: [],
  question: {}
}

const forum = (state = initialState, action) => {
  switch (action.type) {
    case ALL_QUESTIONS:
      return {
        ...state,
        allQuestions: action.data,
      };
    case USER_QUESTIONS:
      return {
        ...state,
        userQuestions: action.data,
      };
    case ALL_COMMENTS:
      // console.log("reducer", action)
      return {
        ...state,
        allComments: action.comments
      }
    case QUESTIONS_COMMENTS:
      return {
        ...state,
        questionComment: action.payload.questionComments,
        question: action.payload.question
      }
    case REPLY:
      // console.log("reducer", action.data);
      // const id = action.data.forumId;
      return {
        ...state,
        allComments: [...state.allComments, action.data],
      }
    case SHARE:

      return {
        ...state,
        allQuestions: [...state.allQuestions, action.data],
        userQuestions: [...state.allQuestions, action.data],
      }
    default:
      return state;
  }
};

export default forum;