import React, { useMemo, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  MenuItem,
  Menu,
  Button,
  Box,
  Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory, withRouter, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store/actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ReactComponent as Logo } from "../svgs/meethub.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    fontWeight: 700,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  btn: {
    marginLeft: theme.spacing(1),
    color: "#848E9C",
    fontWeight: 600,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#DEDEDE",
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  signup: {
    borderRadius: "24px 24px 0px 24px",
    backgroundColor: "#180E40",
    color: "#fff",
    paddingInline: 20,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: "rgba(24, 14, 64, 0.8)",
  },
}));

function PrimaryAppBar({
  isAuthenticated,
  loginError,
  user,
  data,
  handleTextSearch,
  page,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const userName = localStorage.getItem("name");

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    history.push("/login");
  };

  const handleProfileOpen = () => {
    history.push("/user-profile");
  };

  const renderAuthenticatedMenu = React.useMemo(() => {
    return !isAuthenticated && !loginError ? (
      <>
        <MenuItem onClick={() => history.push("/login")}>Sign in</MenuItem>
        <MenuItem onClick={() => history.push("/signup")}>Sign up</MenuItem>
      </>
    ) : (
      <>
        <MenuItem onClick={handleProfileOpen}>My Profile</MenuItem>
        <MenuItem onClick={() => handleLogout()}>Log out</MenuItem>
      </>
    );
  }, [isAuthenticated, loginError]);

  const menuId = "profile-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileOpen}>My Profile</MenuItem>
      <MenuItem onClick={() => handleLogout()}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => history.push("/connect")}>Connect</MenuItem>
      {renderAuthenticatedMenu}
      {/* <MenuItem>
        {isAuthenticated ? null : ( // <Button variant="contained">Share a post</Button>
          <Button variant="contained" onClick={() => history.push("/signup")}>
            Sign up
          </Button>
        )}
      </MenuItem> */}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar color="inherit" className={classes.root} position="relative">
        <Toolbar>
          <Button onClick={() => history.push("/")}>
            <Logo />
          </Button>

          <div className={classes.sectionDesktop}>
            <Button
              className={classes.btn}
              onClick={() => history.push("/connect")}
            >
              Connect
            </Button>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {page === "createProfile" ? null : (
              <div>
                {isAuthenticated && !loginError ? null : ( // </Button> //   Log out // > //   }} //     // page === "index" ? "#fff" : "#333", //     color: "#180E40", //   style={{ //   onClick={() => handleLogout()} //   // onClick={() => history.push("/login")} //   className={classes.btn} // <Button
                  <Button
                    className={classes.btn}
                    onClick={() => history.push("/login")}
                    style={{
                      color: "#180E40",
                      // page === "index" ? "#fff" : "#333",
                    }}
                  >
                    Log in
                  </Button>
                )}
                {isAuthenticated && !loginError ? (
                  // <Button variant="contained" className={classes.btn}>
                  //   Share a post
                  // </Button>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                  >
                    {userName}
                    <IconButton onClick={handleProfileMenuOpen}>
                      <Avatar className={classes.avatar} />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    className={classes.signup}
                    variant="contained"
                    onClick={() => history.push("signup")}
                  >
                    Sign up
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

PrimaryAppBar.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  console.log('%csrc/components/Appbar.js:314 state', 'color: #007acc;', state);
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
  };
}

export default withRouter(connect(mapStateToProps)(PrimaryAppBar));
