import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import CountriesInput from "../../../components/AfricanCountriesInput";
import { db } from "../../../store/firebase/firebase";
import ProfileImageUpload from "../../../components/ProfileUpload";
import validate from "validate.js";

import Select from "react-select";
import countryList from "react-select-country-list";
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";

const schema = {
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  bio: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  profilePhoto: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  steps: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(3),
    color: "#605A5A",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: "rgba(24, 14, 64, 1)",
    marginBottom: theme.spacing(4),
  },
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    paddingInline: theme.spacing(4),
    backgroundColor: "rgba(24, 14, 64, 1)",
    color: "#fff",
    "&$disabled": {
      background: "#fff",
      color: "rgba(24, 14, 64, 1)",
      border: "1px solid rgba(24, 14, 64, 1)",
    },
  },
  disabled: {},
}));

export default function IndividualStep1({
  responseData,
  navigation,
  formState,
  setFormState,
}) {
  const classes = useStyles();

  // const { next } = navigation;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, setFormState]);

  console.log("formState.values: ", formState.values);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleOnBlur = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        phoneNumber: value,
      },
    }));
  };

  // const chosenCountry = (value) => {
  //   setFormState((formState) => ({
  //     ...formState,
  //     values: {
  //       ...formState.values,
  //       country: value,
  //     },
  //   }));
  // };

  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        country: value.label,
      },
    }));
  };

  //const [value, setValue] = useState();
  const profileCallback = (url) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        profilePhoto: url,
      },
    }));
  };

  const userId = localStorage.getItem("user_id");


  const submitProfileDetails = async () => {
    try {
      if (!userId) {
        console.error("User ID is missing.");
        return;
      }
      const userDocRef = doc(db, "users", userId);
      const docSnapshot = await getDocs(userDocRef);
      if (docSnapshot.exists()) {
        await updateDoc(userDocRef, {
          phoneNumber: formState.values.phoneNumber,
          bio: formState.values.bio,
          country: formState.values.country,
          city: formState.values.city,
          profilePhoto: formState.values.profilePhoto
        });
        console.log("Profile details updated successfully.");
        // You might want to add some feedback to the user here, like a success message
      } else {
        console.log("User document does not exist");
        // Handle the case where the user document doesn't exist
      }
    } catch (error) {
      console.error("Error updating profile details:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }

    return (
      <div>
        <Typography gutterBottom className={classes.steps}>
          Step 1 <span style={{ fontWeight: 500 }}>of 2</span>
        </Typography>
        <Typography gutterBottom className={classes.title}>
          Personal Details
        </Typography>

        <Typography gutterBottom className={classes.label}>
          Phone number
        </Typography>

        <div className={classes.field}>
          <PhoneInput
            name="phoneNumber"
            inputStyle={{
              width: "100%",
            }}
            country={"gh"}
            value={formState.values.phoneNumber}
            onBlur={(e) => handleOnBlur(e.target.value)}
          />
        </div>

        <Typography gutterBottom className={classes.label}>
          Country
        </Typography>

        <div className={classes.field}>
          <Select
            options={options}
            value={value}
            isSearchable
            onChange={changeHandler}
          />
        </div>

        <Typography gutterBottom className={classes.label}>
          City
        </Typography>
        <TextField
          name="city"
          value={formState.values.city}
          onChange={handleChange}
          className={classes.field}
          fullWidth
          variant="outlined"
          size="small"
        />

        <Typography gutterBottom className={classes.label}>
          Bio
        </Typography>
        <TextField
          name="bio"
          value={formState.values.bio}
          onChange={handleChange}
          className={classes.field}
          fullWidth
          multiline
          variant="outlined"
          size="small"
          rows={10}
        />

        <Typography gutterBottom className={classes.label}>
          Upload Profile Photo (JPEG, PNG)
        </Typography>
        <ProfileImageUpload profileCallback={profileCallback} />

        <Button
          className={classes.button}
          classes={{
            root: classes.button,
            disabled: classes.disabled,
          }}
          disabled={!formState.isValid}
          variant="contained"
          onClick={submitProfileDetails}
        >
          Submit Profile
        </Button>
      </div>
    );
  }
}
