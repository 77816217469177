import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { Grid, Tab, Tabs, Typography, Link, Box } from "@material-ui/core";
import PersonalProfileForm from "./PersonalProfileForm";
import BusinessProfileForm from "./BusinessProfileForm";
import SignupAlert from "../../components/Alert/SignupAlert";
import { emailSignup } from "../../store/actions";
import validate from "validate.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
  tab: {
    textTransform: "none",
  },
}));

function Form(props) {
  const classes = useStyles();
  const { dispatch, isSignedUp, signupError, isSigningUp } = props;
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { subscribe: false },
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  useEffect(() => {
    signupError ? setOpen(true) : setOpen(false);

    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, signupError]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailSignup = (event) => {
    event.preventDefault();
    dispatch(
      tabValue === 0
        ? emailSignup(
            formState.values.firstName,
            formState.values.lastName,
            formState.values.email,
            formState.values.password,
            "Personal"
          )
        : emailSignup(
            undefined,
            undefined,
            formState.values.email,
            formState.values.password,
            "Business",
            formState.values.name,
            formState.values.accountManagerName
          )
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isSignedUp) {
    return <Redirect to={`/verification/${formState.values.email}`} />;
  }

  return (
    <Grid container direction="column" alignItems="center">
      {signupError ? (
        <SignupAlert
          close={handleClose}
          message={signupError}
          open={open}
          severity="error"
        />
      ) : null}
      <Box mb={3} display="flex" flexDirection="column">
        <Typography variant="h3" className={classes.label} gutterBottom>
          Welcome, It’s good to see you!
        </Typography>
        <Typography
          className={classes.label}
          gutterBottom
          style={{ alignSelf: "center" }}
        >
          Already have an account?{" "}
          <Link
            component={RouterLink}
            to="/login"
            style={{ textDecoration: "underline" }}
          >
            Login
          </Link>
        </Typography>
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Individual" className={classes.tab} />
        <Tab label="Business" className={classes.tab} />
      </Tabs>
      {tabValue === 0 && (
        <PersonalProfileForm
          handleEmailSignup={handleEmailSignup}
          handleChange={handleChange}
          isSigningUp={isSigningUp}
        />
      )}
      {tabValue === 1 && (
        <BusinessProfileForm
          handleEmailSignup={handleEmailSignup}
          handleChange={handleChange}
          isSigningUp={isSigningUp}
        />
      )}
    </Grid>
  );
}

Form.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isSigningUp: state.auth.isSigningUp,
    signupError: state.auth.signupError,
    error: state.auth.error,
    isSignedUp: state.auth.isSignedUp,
  };
}
export default connect(mapStateToProps)(Form);
