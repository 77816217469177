import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Home from "./views";
import Signup from "./views/Signup";
import Login from "./views/Login";
import CreateProfile from "./views/CreateProfile";
import UpdateProfile from "./views/UpdateProfile";
// import Dashboard from "./views/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ConnectProfileDetails from "./views/Connect/components/ConnectProfileDetails";

import Connect from "./views/Connect";
import ProjectPage from "./views/Connect/components/ProjectPage"
import MeethubProjects from "./views/Connect/components/MeethubProjects"
import Challenges from "./views/Challenges"
import ChallengeDetails from "./views/Challenges/ChallengeDetails";
import MakeSubmission from "./views/Challenges/MakeSubmission"
import { connect } from "react-redux";
import UserProfile from "./views/UserProfile";
import VerificationPage from "./views/VerificationPage/VerificationPage";

const Routes = (props) => {
  const { isAuthenticated, isVerifying } = props;

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/create-profile" component={CreateProfile} />
      <Route exact path="/update-profile" component={UpdateProfile} />
      <Route exact path="/connect" component={Connect} />
      <Route path="/connect/:id" component={Connect} />
      <Route path="/projects" component={MeethubProjects} />
      <Route path="/project/:id" component={ProjectPage} />
      <Route exact path="/challenges" component={Challenges} />
      <Route exact path="/user-profile" component={UserProfile} />
      <Route exact path="/verification/:email" component={VerificationPage} />
      {/* <Route exact path="/challenge-details/:id" component={ChallengeDetails} /> */}
      <Route
        exact
        path="/connect-profile-details/:id"
        component={ConnectProfileDetails}
      />
      <ProtectedRoute
        exact
        path="/challenge-details/:id"
        component={ChallengeDetails}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/make-submission/:id"
        component={MakeSubmission}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      {/* <ProtectedRoute
        exact
        path="/dashboard"
        component={Dashboard}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      /> */}
      {/* <Route exact path="/profile/:id" component={Profiles} />
      <Route exact path="/profile-details/:id" component={ProfileDetails} />
      <Route exact path="/forum" component={Forum} /> */}
    </Switch>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default withRouter(connect(mapStateToProps)(Routes));
