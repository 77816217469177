import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { myFirebase } from "../../store/firebase/firebase";
import AppBar from "../../components/Appbar";
import { useParams } from "react-router-dom";
import { Typography, Grid, Button } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  logo: {
    fontSize: 100,
    marginBottom: theme.spacing(2),
    color: "#460CEB",
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  resendButton: {
    color: "black",
    textDecoration: "underline",
    fontWeight: 700,
    cursor: "pointer",
  },
}));

const VerificationPage = () => {
  const classes = useStyles();
  let { email } = useParams();
  const [resent, setResent] = React.useState(false);

  const resendVerificationEmail = () => {
    myFirebase
      .auth()
      .currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      })
      .then(() => {
        setResent(true);
      })
      .catch((error) => {
        console.error("Error resending verification email:", error);
        // Handle error, if any
      });
  };

  return (
    <div>
      <div>
        <AppBar page="createProfile"/>
      </div>
      <div className={classes.root}>
        <MailOutlineIcon className={classes.logo} />
        <Typography variant="h5" className={classes.text}>
          We sent an email with a verification link to {email}.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          If you haven’t received any email, check your spam folder.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Didn’t get it?{" "}
          <Button
            className={classes.resendButton}
            onClick={resendVerificationEmail}
            variant="text"
          >
            Resend it
          </Button>
        </Typography>
      </div>
    </div>
  );
};

export default VerificationPage;
