import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import {
  db,
  myFirebase,
  timeStamp,
} from "../firebase/firebase"; // Assuming firebase.js is in the same directory
import { collection, doc, getDoc, setDoc, where, query, getDocs } from "firebase/firestore";

const auth = getAuth(myFirebase); 
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const githubProvider = new GithubAuthProvider();

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const account = localStorage.getItem("account");

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

const requestSignup = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};

const receiveSignup = (user) => {
  return {
    type: SIGNUP_SUCCESS,
    user,
  };
};

const signupError = (error) => {
  return {
    type: SIGNUP_FAILURE,
    payload: error,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  const auth = getAuth(myFirebase); // Get auth instance
  signOut(auth)
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch((error) => {
      dispatch(logoutError());
    });
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const auth = getAuth(myFirebase); // Get auth instance
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(receiveLogin(user));
      }
    });
  } catch (error) {
    console.error(error); // Use console.error for errors
  }
};


const getUserData = async (dispatch, authUser) => {
  try {
    const usersCollectionRef = collection(db, "users");
    const docRef = doc(usersCollectionRef, authUser.user.uid);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      dispatch(receiveLogin(authUser));
      localStorage.setItem("account", docSnapshot.data().account);
    } else {
      dispatch(loginError("No such User!"));
    }
  } catch (error) {
    dispatch(loginError("Error getting user: ", error.message));
  }
};

const saveUserData = async (dispatch, authUser) => {
  const usersCollectionRef = collection(db, "users");
  const userDocRef = doc(usersCollectionRef, authUser.user.uid);
  try {
    await setDoc(userDocRef, {
      name: authUser.user.displayName,
      account: account,
      email: authUser.user.email,
      created: timeStamp,
      createdBy: authUser.user.uid,
      activated: false,
      status: "unverified",
      isAdmin: false,
    }, { merge: true }); 
    dispatch(receiveSignup(authUser));
  } catch (error) {
    console.log(error.message);
    dispatch(signupError(error.message));
  }
};

export const loginUser = (email, password) => async (dispatch) => {
  dispatch(requestLogin());
  fetchSignInMethodsForEmail(auth, email)
    .then((result) => {
      if (result.length !== 0) {
        signInWithEmailAndPassword(auth, email, password) // Use modular signInWithEmailAndPassword
          .then(async (authUser) => {
            if (!authUser.user.emailVerified) {
              // Sign out the user if their email is not verified
              signOut(auth); // Use modular signOut
              dispatch(loginError("Please check your email to verify your account"));
              return;
            }
            const usersCollectionRef = collection(db, "users");
            const userDocRef = doc(usersCollectionRef, authUser.user.uid);
            getDoc(userDocRef) // Use modular getDoc
              .then(async (docSnapshot) => {
                if (docSnapshot.exists()) {
                  const userData = docSnapshot.data();
                  localStorage.setItem("user_id", authUser.user.uid);
                  localStorage.setItem("name", userData.name ?? userData.firstName + " " + userData.lastName);
                  await getUserData(dispatch, authUser);
                } else {
                  console.log("Account does not exist");
                }
              })
              .catch((error) => {
                console.log(error.message);
                dispatch(signupError(error.message));
              });
          })
          .catch((err) => {
            dispatch(loginError("Invalid Email or Password"));
          });
      } else {
        dispatch(loginError("Your account does not exist, Please Sign Up"));
      }
    });
};


export const googleLogin = () => (dispatch) => {
  dispatch(requestLogin());
  signInWithPopup(auth, provider) // Use modular signInWithPopup
    .then((socialAuthUser) => {
      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, socialAuthUser.user.uid);
      getDoc(userDocRef) // Use modular getDoc
        .then(async (docSnapshot) => {
          if (docSnapshot.exists()) {
            localStorage.setItem("user_id", socialAuthUser.user.uid);
            localStorage.setItem("name", socialAuthUser.user.displayName);
            getUserData(dispatch, socialAuthUser);
            dispatch(receiveLogin(socialAuthUser));
          } else {
            await saveUserData(dispatch, socialAuthUser);
          }
        })
        .catch((error) => {
          dispatch(loginError(error.message));
        });
    })
    .catch((error) => {
      dispatch(loginError(error.message));
    });
};

export const googleSignup = () => (dispatch) => {
  dispatch(requestSignup());
  signInWithPopup(auth, provider)
    .then((socialAuthUser) => {
      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, socialAuthUser.user.uid);

       getDoc(userDocRef)
        .then(async (docSnapshot) => {
          if (docSnapshot.exists) {
            console.log("Email Already Exists, Go to Login");
            dispatch(signupError("Email Already Exists, Go to Login"));
          } else {
            localStorage.setItem("user_id", socialAuthUser.user.uid);
            localStorage.setItem("name", socialAuthUser.user.displayName);
            localStorage.setItem(
              "new_user",
              socialAuthUser.additionalUserInfo &&
                socialAuthUser.additionalUserInfo.isNewUser
                ? "1"
                : "0"
            );

            await saveUserData(dispatch, socialAuthUser);
          }
        })
        .catch((error) => {
          console.log(error.message);
          dispatch(signupError(error.message));
        });
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(signupError(error.message));
    });
};

export const emailSignup =
  (
    firstName,
    lastName,
    email,
    password,
    accountType,
    name,
    accountManagerName
  ) =>
  (dispatch) => {
    const individualAccount = {
      firstName,
      lastName,
    };
    const businessAccount = {
      name,
      accountManagerName,
    };
    myFirebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((socialAuthUser) => {
        
      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, socialAuthUser.user.uid);

      getDoc(userDocRef) // Use modular getDoc
          .then((docSnapshot) => {
            if (docSnapshot.exists) {
              console.log("Email Already Exists, Go to Login");
              dispatch(signupError("Email Already Exists, Go to Login"));
            } else {
              localStorage.setItem("user_id", socialAuthUser.user.uid);
              localStorage.setItem("account", accountType);
              localStorage.setItem("name", accountType === "Personal" ? `${firstName} ${lastName}` : name);
              localStorage.setItem(
                "new_user",
                socialAuthUser.additionalUserInfo &&
                  socialAuthUser.additionalUserInfo.isNewUser
                  ? "1"
                  : "0"
              );

              return db
                .collection("users")
                .doc(socialAuthUser.user.uid)
                .set(
                  {
                    ...(accountType === "Personal"
                      ? individualAccount
                      : businessAccount),
                    account: accountType,
                    email: email,
                    created: timeStamp,
                    createdBy: socialAuthUser.user.uid,
                    activated: false,
                    status: "unverified",
                    isAdmin: false,
                  },
                  { merge: true }
                )
                .then(() => {
                  dispatch(receiveSignup(socialAuthUser));
                  // history.push("/login");
                })
                .catch((error) => {
                  console.log(error.message);
                  dispatch(signupError(error.message));
                });
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch(signupError(error.message));
          });
      })
      .then(() => {
        return auth.currentUser.sendEmailVerification({
          url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
        });
      })
      .catch((error) => {
        dispatch(signupError(error.message));
      });
  };

export const facebookSignUp = () => (dispatch) => {
  /* Social Sign In Method Provider */
  //const facebookProvider = new firebase.auth.FacebookAuthProvider();
  // eslint-disable-next-line no-unused-vars
  let handle, socialProvider, userData;
  // Call action
  auth
    .signInWithPopup(facebookProvider)
    .then((data) => {
      userData = data.user;
      return userData;
    })
    .then((userData) => {
      socialProvider = userData.providerData[0].providerId;
      // concate handle name
      handle = userData.displayName;
      handle = handle.replace(/\s/g, "");
      // New data user
      const newUser = {
        email: userData.email,
        account: account,
        handle: handle.toLowerCase(),
        name: userData.displayName,
        birth: "",
        gender: "",
        imageUrl: userData.photoURL,
        phoneNumber: userData.phoneNumber,
        userId: userData.uid,
        provider: socialProvider,
        isAdmin: false,
      };

       // Use collection and doc references for better readability and consistency
       const usersCollectionRef = collection(db, "users");
       const userDocRef = doc(usersCollectionRef, userData.uid);
       getDoc(userDocRef)
         .then((docSnapshot) => {
           if (docSnapshot.exists()) {
             console.log("Email Already Exists, Go to Login");
             dispatch(signupError("Email Already Exists, Go to Login"));
           } else {
             // Use set with merge option for creating or updating the document
            return setDoc(userDocRef, newUser, { merge: true })
              .then(() => {
                dispatch(receiveSignup(userData));
                localStorage.setItem("user_id", userData.uid);
                //history.push("/login");
              })
              .catch((error) => {
                console.log(error.message);
                dispatch(signupError(error.message));
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
          dispatch(signupError(error.message));
        });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      console.log(error);
      // const errorMessage = error.message;
      // console.log(errorMessage);
      if (errorCode === "auth/popup-closed-by-user") {
        dispatch(
          signupError(
            "The popup has been closed by the user before finalizing the operation."
          )
        );
      } else if (errorCode === "auth/credential-already-in-use") {
        dispatch(
          signupError(
            "This credential is already associated with a different user account."
          )
        );
      } else if (errorCode === "auth/email-already-in-use") {
        dispatch(
          signupError("The email address is already in use by another account.")
        );
      } else if (errorCode === "auth/invalid-credential") {
        dispatch(signupError("Invalid credential, please try again."));
      } else if (errorCode === "auth/cancelled-popup-request") {
        dispatch(
          signupError(
            "This operation has been cancelled due to another conflicting popup being opened."
          )
        );
      } else if (errorCode === undefined) {
        return null;
      } else {
        dispatch(signupError("Something went wrong. Please try again."));
      }
    });
};

export const facebookLogin = () => (dispatch) => {
  /* Get more information for user */
  // Call action
  auth
    .signInWithPopup(facebookProvider)
    .then((data) => {
      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, data.user.uid);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            localStorage.setItem("user_id", data.user.uid);
            dispatch(receiveLogin(data));
          } else {
            dispatch(loginError("Email does not exist go to signup"));
          }
        })
        .catch((error) => {
          dispatch(loginError(error.message));
        });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      console.log(error);
      // const errorMessage = error.message;
      // console.log(errorMessage);
      if (errorCode === "auth/popup-closed-by-user") {
        dispatch(
          loginError(
            "The popup has been closed by the user before finalizing the operation."
          )
        );
      } else if (errorCode === "auth/credential-already-in-use") {
        dispatch(
          loginError(
            "This credential is already associated with a different user account."
          )
        );
      } else if (errorCode === "auth/email-already-in-use") {
        dispatch(
          loginError("The email address is already in use by another account.")
        );
      } else if (errorCode === "auth/invalid-credential") {
        dispatch(loginError("Invalid credential, please try again."));
      } else if (errorCode === "auth/cancelled-popup-request") {
        dispatch(
          loginError(
            "This operation has been cancelled due to another conflicting popup being opened."
          )
        );
      } else if (errorCode === undefined) {
        return null;
      } else {
        dispatch(loginError("Something went wrong. Please try again."));
      }
    });
};

export const githubSignUp = () => (dispatch) => {
  /* Social Sign In Method Provider */
  // eslint-disable-next-line no-unused-vars
  let handle, socialProvider, userData;
  // Call action
  auth
    .signInWithPopup(githubProvider)
    .then((data) => {
      userData = data.user;
      return userData;
    })
    .then((userData) => {
      socialProvider = userData.providerData[0].providerId;
      // concate handle name
      handle = userData.displayName;
      handle = handle.replace(/\s/g, "");
      // New data user
      const newUser = {
        email: userData.email,
        account: account,
        handle: handle.toLowerCase(),
        name: userData.displayName,
        birth: "",
        gender: "",
        imageUrl: userData.photoURL,
        phoneNumber: userData.phoneNumber,
        userId: userData.uid,
        provider: socialProvider,
        isAdmin: false,
      };

      // Use collection and doc references
      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, userData.uid);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            console.log("Email Already Exists, Go to Login");
            dispatch(signupError("Email Already Exists, Go to Login"));
          } else {
            // Use setDoc with merge option
            return setDoc(userDocRef, newUser, { merge: true })
              .then(() => {
                localStorage.setItem("user_id", userData.uid);
                dispatch(receiveSignup(userData));
                //history.push("/login");
              })
              .catch((error) => {
                console.log(error.message);
                dispatch(signupError(error.message));
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
          dispatch(signupError(error.message));
        });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      console.log(error);
      // const errorMessage = error.message;
      // console.log(errorMessage);
      if (errorCode === "auth/popup-closed-by-user") {
        dispatch(
          signupError(
            "The popup has been closed by the user before finalizing the operation."
          )
        );
      } else if (
        errorCode === "auth/account-exists-with-different-credential"
      ) {
        dispatch(
          signupError(
            "This credential is already associated with a different user account."
          )
        );
      } else if (errorCode === "auth/email-already-in-use") {
        dispatch(
          signupError("The email address is already in use by another account.")
        );
      } else if (errorCode === "auth/invalid-credential") {
        dispatch(signupError("Invalid credential, please try again."));
      } else if (errorCode === "auth/cancelled-popup-request") {
        dispatch(
          signupError(
            "This operation has been cancelled due to another conflicting popup being opened."
          )
        );
      } else if (errorCode === undefined) {
        return null;
      } else {
        dispatch(signupError("Something went wrong. Please try again."));
      }
    });
};

export const githubLogin = () => (dispatch) => {
  /* Get more information for user */
  // Call action
  auth
    .signInWithPopup(githubProvider)
    .then((data) => {
      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, data.user.uid);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            localStorage.setItem("user_id", data.user.uid);
            dispatch(receiveLogin(data));
          } else {
            dispatch(loginError("Email does not exist go to signup"));
          }
        })
        .catch((error) => {
          dispatch(loginError(error.message));
        });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      console.log(error);
      // const errorMessage = error.message;
      // console.log(errorMessage);
      if (errorCode === "auth/popup-closed-by-user") {
        dispatch(
          loginError(
            "The popup has been closed by the user before finalizing the operation."
          )
        );
      } else if (
        errorCode === "auth/account-exists-with-different-credential"
      ) {
        dispatch(
          loginError(
            "This credential is already associated with a different user account."
          )
        );
      } else if (errorCode === "auth/email-already-in-use") {
        dispatch(
          loginError("The email address is already in use by another account.")
        );
      } else if (errorCode === "auth/invalid-credential") {
        dispatch(loginError("Invalid credential, please try again."));
      } else if (errorCode === "auth/cancelled-popup-request") {
        dispatch(
          loginError(
            "This operation has been cancelled due to another conflicting popup being opened."
          )
        );
      } else if (errorCode === undefined) {
        return null;
      } else {
        dispatch(loginError("Something went wrong. Please try again."));
      }
    });
};

export const verifyAuth = () => async (dispatch) => {
  // const userId = localStorage.getItem("user_id");
  dispatch(verifyRequest());
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      // console.log("user", user.email)
      // if (userId !== "null" || userId !== null || userId !== "") {
      let dataQuery = await db
        .collection("users")
        .where("email", "==", user.email)
        .get();
      let data = dataQuery.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      // console.log("data", data[0])
      dispatch(receiveLogin(data[0]));
    } else {
      dispatch(logoutUser());
      // }
      dispatch(verifySuccess());
    }
  });
};
