import { collection, getDocs, query, where } from "firebase/firestore";
import { db, myFirebase } from "../firebase/firebase";
import {
    ALL_QUESTIONS, USER_QUESTIONS, ALL_COMMENTS, QUESTIONS_COMMENTS, REPLY
} from "../actions/actionTypes";

export const getQuestions = (data, setLoader) => async (dispatch, getState) => {
    let email = getState().auth?.user?.email;
    try {
        // setLoader(true)
        // Fetch all questions
        const questionsCollectionRef = collection(db, "forum");
        const questionsSnapshot = await getDocs(questionsCollectionRef);
        let data = questionsSnapshot.docs.map((doc) => ({
            ...doc.data(),
            forumId: doc.id,
        }));
        // Fetch all comments
        const commentsCollectionRef = collection(db, "comments");
        const commentsSnapshot = await getDocs(commentsCollectionRef);
        let comments = commentsSnapshot.docs.map((doc) => ({
            ...doc.data(),
            commentId: doc.id,
        }));
        // Dispatch actions for all comments and questions
        dispatch({ type: ALL_COMMENTS, comments });
        dispatch({ type: ALL_QUESTIONS, data });
        // Filter for user's questions
        let userQuestions = data.filter(el => el.email === email);
        dispatch({ type: USER_QUESTIONS, data: userQuestions });
        // Get comments for the first question (if any)
        if (data.length > 0) {
            let questionId = data[0]?.forumId;
            const questionComments = comments?.filter(el => el?.forumId == questionId);
            dispatch({ 
                type: QUESTIONS_COMMENTS, 
                payload: { questionComments, question: data[0] } 
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const sharePost = (data, setLoader) => async (dispatch) => {
    try {
        setLoader(true)
        const docRef = myFirebase.firestore().collection("forum").doc();
        await docRef.set(data);
        dispatch({ type: 'SHARE', data: { ...data, forumId: docRef.id } })
        setLoader(false)
    } catch (error) {
        console.log(error)
    }
}

export const addComment = (data, setLoader) => async (dispatch) => {
    try {
        setLoader(true)
        const docRef = myFirebase.firestore().collection("comments").doc();
        await docRef.set(data);
        dispatch({ type: REPLY, data: { ...data, id: docRef.id } })
        setLoader(false)
    } catch (error) {
        console.log(error)
    }
}


// export const getUserQuestions = (email, setLoader) => async (dispatch) => {
//     try {
//         let dataQuery = await db.collection("forum").where("email", "==", email).get();
//         let data = dataQuery.docs.map((doc) => {
//             return {
//                 ...doc.data(),
//                 id: doc.id,
//             };
//         });
//         console.log(data)
//     } catch (error) {
//         console.log(error)
//     }
// }