import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import Routes from "./Routes";
import theme from "./theme";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, verifyAuth } from "./store/actions";
import { getQuestions } from "./store/actions/form";
import { Snackbar } from "@material-ui/core";
import Alert from "./components/Alert/Alert";
// import validate from "validate.js";
import setSnackbar from "./store/actions/snackBar";
// import validators from "./common/validators";
const browserHistory = createBrowserHistory();

// validate.validators = {
//   ...validate.validators,
//   ...validators,
// };

function App() {
  // const state = useSelector(state => state)
  // console.log("state", state)
  const dispatch = useDispatch();
  const snackbarState = useSelector((state) => state.snackbar);
  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(verifyAuth());
    dispatch(getQuestions());
  }, []);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, "", ""));
  };
  // const location = useLocation();
  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);
  return (
  
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Snackbar
          open={snackbarState.open}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarState.severity}>
            {snackbarState.message}
          </Alert>
        </Snackbar>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
