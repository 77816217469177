export const SET_SNACKBAR = "OPEN_SNACKBAR";

const setSnackbar = (open,severity = "",message = "") => ({
  type: SET_SNACKBAR,
  open,
  severity,
  message,
});

export default setSnackbar;
