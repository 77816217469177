// Suggested code may be subject to a license. Learn more: ~LicenseLog:3793986949.
// Suggested code may be subject to a license. Learn more: ~LicenseLog:3060612439.

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider } from "firebase/auth";
import { getFirestore, serverTimestamp, FieldValue, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const myFirebase = initializeApp(firebaseConfig);
//const baseDb = getFirestore(myFirebase);
//export const db = baseDb;
export const db = getFirestore(myFirebase); // Initialize Firestore

// db.settings({ ignoreUndefinedProperties: true });

export const storageRef = getStorage(myFirebase);

export const auth = getAuth(myFirebase); // Initialize auth
export const provider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const githubProvider = new GithubAuthProvider();

//export const user = (uid) => baseDb.doc(`users/${uid}`);

//export const users = () => baseDb.collection("users");

export const user = (uid) => collection(db, `users/${uid}`);
export const users = () => collection(db, "users");

export const timeStamp = serverTimestamp();
export const fieldValue = FieldValue;
