import React, { useEffect, useState } from "react";
import { db } from "../../../store/firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import clsx from 'clsx';
import {
    makeStyles,
    Typography,
    Grid,
    Avatar,
    Button,
    Modal,
    Paper,
    Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        background: "#fff",
        boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
        padding: theme.spacing(3, 1),
        height: "100%",
    },
    center: {
        display: "flex",
        justifyContent: "center",
    },
    avatar: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    showingResults: {
        marginTop: "35px",
        // fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "14px",
        // lineHeight: "17px",
        color: "#485465",
    },
    paginationContainer: {
        listStyle: "none",
        display: "flex",
        marginTop: "35px",
        "& a": {
            border: "1px solid #ABB3BF",
            boxSizing: "border-box",
            borderRadius: "3px",
            padding: "10px 15px",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "16px",
            cursor: "pointer",
            margin: "0px 2px",
            "&:hover": {
                background: "#180E40",
                color: "#fff",
            },
        },
    },
    paginationActive: {
        background: "#180E40",
        color: "#fff",
        border: "none",
    },
    disabledBtn: {
        color: "#ABB3BF",
    },
    title: {
        color: "#180E40",
        fontWeight: "bold",
        fontSize: 40,
        lineHeight: "50px",
    },
    modalStyle1: {
        position: "absolute",
        top: "10%",
        overflow: "scroll",
        height: "100%",
        display: "block",
    },
    content: {
        // backgroundColor: "#E3E8EC",
        padding: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4),
        },
    },
    header: {
        textAlign: "center",
        marginTop: "4%",
        marginBottom: "4%",
    },
    paper: {
        marginBlock: theme.spacing(4),
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 400,
        color: "rgba(24, 14, 64, 1)",
        lineHeight: "30px",
    },

    root: {
        flexGrow: 1,
        paddingTop: 0,
        padding: theme.spacing(8), // Adjust padding as needed
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4),
        },
    },
    imageContainer: {
        marginRight: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
            marginBottom: theme.spacing(3),
        },
    },
    bigImage: {
        width: "100%",
        height: "588px",
        objectFit: "cover",
        marginBottom: theme.spacing(2),
        borderRadius: theme.spacing(1), // Add slight border radius
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow
    },
    thumbnail: {
        width: "calc(33.33% - 16px)", // Adjust as needed, considering spacing
        height: "150px",
        objectFit: "cover",
        margin: theme.spacing(0.5),
        cursor: "pointer",
        borderRadius: theme.spacing(1),
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        opacity: 0.7, // Slightly reduce opacity for unselected thumbnails
        "&:hover": {
            opacity: 1,
        },
    },
    selectedThumbnail: {
        opacity: 1,
    },
    title: {
        color: "#180E40", // Dark blue
        fontWeight: "bold",
        fontSize: 40,
        lineHeight: "50px",
        marginBottom: theme.spacing(3),
    },
    categoryTag: {
        borderRadius: "8px",
        background: "#DAE7FF",
        padding: "8px 12px",
        display: "inline-block",
        color: "#1F66EC", // Lighter blue
        marginBottom: theme.spacing(2),
    },
    description: {
        fontSize: 18,
        lineHeight: "30px",
        color: "#485465", // Grayish color
    },
}));

const ProjectDetailsContainer = ({ productId }) => {
    const classes = useStyles();
    const [product, setProduct] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const projectDocRef = doc(db, "projects", productId);
                const docSnapshot = await getDoc(projectDocRef);

                if (docSnapshot.exists()) {
                    const projectData = docSnapshot.data();
                    setProduct(projectData);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching project:", error);
            }
        };

        if (productId) {
            fetchProject();
        }
    }, [productId]);

    useEffect(() => {
        if (product && product.images && product.images.length > 0) {
            setSelectedImage(product.images[0].imageUrl);
        }
    }, [product]);

    if (!product) {
        console.log(productId)
        return <div>Loading...</div>;
    }
    console.log(product)
    return (
        <Grid container className={classes.root}>
            {product.images && product.images.length > 0 ? (
                <>
                    <Grid item xs={12} md={6} className={classes.imageContainer}>
                        {/* Main Image */}
                        {selectedImage && (
                            <img
                                src={selectedImage}
                                alt="Selected Project"
                                className={classes.bigImage}
                            />
                        )}

                        {/* Thumbnails */}
                        <Box display="flex">
                            {product.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.imageUrl}
                                    alt={`Project ${index}`}
                                    onClick={() => setSelectedImage(image.imageUrl)}
                                    className={clsx(classes.thumbnail, {
                                        [classes.selectedThumbnail]: selectedImage === image.imageUrl,
                                    })}
                                />
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {/* Project Details */}
                        <Typography variant="h1" className={classes.title}>
                            {product.name}
                        </Typography>
                        <Typography variant="body1" className={classes.categoryTag}>
                            {product.category}
                        </Typography>
                        <Typography variant="body1" className={classes.description}>
                            {product.special}
                        </Typography>
                        <Typography variant="body1" className={classes.description}>
                            {product.description}
                        </Typography>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    {/* Project Details (Full Width) */}
                    <Typography variant="h1" className={classes.title}>
                        {product.name}
                    </Typography>
                    <Typography variant="body1" className={classes.categoryTag}>
                        {product.category}
                    </Typography>
                    <Typography variant="body1" className={classes.description}>
                            {product.special}
                        </Typography>
                    <Typography variant="body1" className={classes.description}>
                        {product.description}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default ProjectDetailsContainer;