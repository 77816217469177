import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    lineHeight: "36px",
    color: "rgba(24, 14, 64, 1)",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "rgba(24, 14, 64, 1)",
    color: "#fff",
  },
  eye: {
    cursor: "pointer",
  },
}));

function BusinessProfileForm(props) {
  const classes = useStyles();
  const [passwordShown, setPasswordShown] = useState(false);
  const {handleEmailSignup, handleChange, isSigningUp} = props;

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Grid item sm={7} md={5} lg={4}>
      <Typography className={classes.label} gutterBottom>
        Business Name
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        name="name"
        type="text"
        onChange={handleChange}
        className={classes.field}
        fullWidth
        placeholder="Enter business name"
      />
      <Typography className={classes.label} gutterBottom>
        Account Manager Name
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        name="accountManagerName"
        type="text"
        onChange={handleChange}
        className={classes.field}
        fullWidth
        placeholder="Enter account manager name"
      />
      <Typography className={classes.label} gutterBottom>
        Email address
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        name="email"
        type="email"
        onChange={handleChange}
        className={classes.field}
        fullWidth
        placeholder="Enter email address"
      />

      <Typography className={classes.label} gutterBottom>
        Password
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        name="password"
        type={passwordShown ? "text" : "password"}
        onChange={handleChange}
        className={classes.field}
        fullWidth
        placeholder="Enter password here"
        InputProps={{
          endAdornment: (
            <VisibilityOutlinedIcon
              className={classes.eye}
              onClick={togglePasswordVisibility}
            />
          ),
        }}
      />

      <Button fullWidth disabled={isSigningUp} className={classes.button} variant="contained" onClick={handleEmailSignup}>
        Create Account
      </Button>
    </Grid>
  );
}

export default BusinessProfileForm;
