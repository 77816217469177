import React from "react";
import ProductForm from "../components/ProductForm";

const Step2 = ({ navigation, formState, setFormState }) => {
  const step = 2;
  const newProps = { navigation, formState, setFormState, step };
  return <ProductForm {...newProps} />;
};

export default Step2;
