import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "../../../components/Appbar";
// import Footer from "../../../components/Footer";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(9, 0),
  },

  title: {
    fontSize: 24,
    fontWeight: 500,
    color: "#000",
    margin: theme.spacing(3, 0),
  },
  link: {
    fontSize: 14,
    fontWeight: 400,
    color: "rgba(24, 14, 64, 1)",
    textDecoration: "underline",
  },
}));

export default function Success() {
  const classes = useStyles();

  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    history.push("/");
  };

  return (
    <div className={classes.content}>
      <CheckCircleIcon
        style={{ color: "rgba(24, 14, 64, 1)", fontSize: "100px" }}
      />
      <Typography className={classes.title}>
        Account created sucessfully!
      </Typography>
      <Link className={classes.link} href="#" onClick={handleClick}>
        Done
      </Link>
    </div>
  );
}
