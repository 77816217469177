import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import Appbar from "../../components/Appbar";
import Profiles from "./components/Profiles";
import AllProjects from "./components/AllProjects";
// import ConnectProfileDetails from "./components/ConnectProfileDetails";
import AppsIcon from "@material-ui/icons/Apps";
import { useLocation } from 'react-router-dom';
import ProjectDetails from "./components/ProjectDetails";

const drawerWidth = 150;

function Connect() { // Wrap the code inside a functional component
  const location = useLocation();
  const projectDetails = location.state?.projectDetails;
  if (projectDetails) {
    return <ProjectDetails project={projectDetails} />;
  }
  // You can return something else if projectDetails is not available
  return <div>Loading project details...</div>; 
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icons: {
    minWidth: "40px",
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedPage(index);
  };

  return (
    <>
      <Appbar />
      <div className={classes.root}>
        <CssBaseline />

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}></div>
          {/* <Divider /> */}
          {open ? (
            <IconButton onClick={handleDrawerClose} style={{ borderRadius: 0 }}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen} style={{ borderRadius: 0 }}>
              <ChevronRightIcon />
            </IconButton>
          )}

          <List>
            <ListItem
              button
              key="individual"
              selected={selectedPage === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon className={classes.icons}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Individuals" />
            </ListItem>
            <ListItem
              button
              key="businesses"
              selected={selectedPage === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon className={classes.icons}>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Hubs & DIHs" />
            </ListItem>
            <ListItem
              button
              key="projects"
              selected={selectedPage === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon className={classes.icons}>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {selectedPage === 0 && <Profiles userType="Personal" />}
          {selectedPage === 1 && <Profiles userType="Business" />}
          {selectedPage === 2 && <AllProjects />}
        </main>
      </div>
    </>
  );
}
