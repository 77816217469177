import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  InputBase,
} from "@material-ui/core";
import setSnackbar from "../../store/actions/snackBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { storageRef, db, fieldValue } from "../../store/firebase/firebase";
import { collection, doc, updateDoc, getDocs, addDoc, increment } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "40px auto",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: `translate(-50%, -50%)`,
    // backgroundColor: "#fff",
    // borderRadius: "24px",
  },
  header: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "132.24%",
    color: "#252D39",
    margin: "0 auto",
  },
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    // height: "300px",
    // margin: "40px 0px",
    // overflowY: "scroll",
  },
  fileInputContainer: {
    height: "150px",
    border: "0.5px dashed #180E40",
    borderRadius: "3px",
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  commentInput: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    border: "0.5px solid #CDD3DC",
    boxSizing: "border-box",
    marginBottom: "10px",
    padding: "5px",
  },
  textInput: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "150%",
    padding: "10px 16px 9px",
    marginBottom: "15px",
    color: "#252D39",
    border: "0.5px solid #CDD3DC",
    boxSizing: "border-box",
  },
  inputLabel: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    color: "#848E9C",
    marginBottom: "10px",
  },
  fileDetails: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    marginBottom: "10px",
    color: "#252D39",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "30px 8%",
  },
  submitBtn: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    background: "#180E40",
    borderRadius: "3px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#180E40",
    },
    "&:disabled": {
      backgroundColor: "#120a33",
    },
  },
  cancelBtn: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#180E40",
    background: "#fff",
    cursor: "pointer",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

function MakeSubmission() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const uploadInputRef = useRef();
  const [challenge, setChallenge] = useState([]);
  const challengeTitle = challenge?.title;
  const [submiting, setSubmiting] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [addedSections, setAddedSections] = useState([]);

  const submissionSchema = {
    fileUrl: "",
    comment: "",
    fileName: "",
    score: 0,
    sections: [],
    fields: {},
  };

  const [submissionData, setSubmissionData] = useState(submissionSchema);

  const handleAddedFieldsValueChange = (index, event) => {
    const { name, value } = event.target;
    setSubmissionData((prev) => ({
      ...prev,
      fields: { ...prev.fields, [name]: value },
    }));
  };
  const handleAddedSectionsValueChange = (
    event,
    sectionIndex,
    fieldIndex,
    title
  ) => {
    const { name, value } = event.target;
    const inputs = [...addedSections];
    inputs[sectionIndex] = {
      ...inputs[sectionIndex],
      sectionLabel: title,
      [name]: value,
    };
    setAddedSections(inputs);
  };
  useEffect(() => {
    const fetchChallenge = async () => {
      try {
        const challengeDocRef = doc(db, "challenges", id);
        const docSnapshot = await getDocs(challengeDocRef);
        if (docSnapshot.exists()) {
          setChallenge(docSnapshot.data());
        } else {
          console.log("No such challenge!");
        }
      } catch (error) {
        console.error("Error fetching challenge:", error);
      }
    };
    fetchChallenge();
  }, [id]);
    
  useEffect(() => {
    const fileUpload = () => {
      const name = new Date().getTime() + selectedFile.name;
      const submissionsStorageRef = storageRef.ref();
      setUploadedFileName(`challenges/${challengeTitle}/submissions/${name}`);

      var submissionRef = submissionsStorageRef.child(
        `challenges/${challengeTitle}/submissions/${name}`
      );

      var uploadTask = submissionRef.put(selectedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // console.log("Upload is " + progress + "% done");
          setPercent(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          dispatch(setSnackbar(true, "info", "File upload failed"));
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // console.log("File available at", downloadURL);
            setSubmissionData({ ...submissionData, fileUrl: downloadURL });
            setIsSelected(true);
          });
        }
      );
    };
    selectedFile && fileUpload();
  }, [selectedFile]);

  const handleCommentChange = (e) => {
    setSubmissionData({ ...submissionData, comment: e.target.value });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    console.log(file.name);
    setSubmissionData({ ...submissionData, fileName: file.name });
    // console.log(file);
    setSelectedFile(file);

    setIsSelected(true);
  };

  const handleRemoveFile = () => {
    // Create a reference to the file to delete
    const submissionsStorageRef = storageRef.ref();
    var deleteRef = submissionsStorageRef.child(uploadedFileName);

    // Delete the file
    deleteRef
      .delete()
      .then(() => {
        // File deleted successfully
        setSubmissionData({ ...submissionData, fileUrl: "", fileName: "" });
        setPercent(0);
        setIsSelected(false);
      })
      .catch((error) => {
        //an error occurred!
        dispatch(setSnackbar(true, "info", "failed to delete file"));
        setPercent(0);
        setIsSelected(false);
      });
  };

  useEffect(() => {
    setSubmissionData((prev) => ({
      ...prev,
      sections: addedSections,
      // fields: addedFields,
    }));
  }, [addedSections]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submissionData.fileUrl === "") {
      return dispatch(setSnackbar(true, "warning", "Select a file to upload"));
    }
    setSubmiting(true);
    try {
      const challengeDocRef = doc(db, "challenges", id);
      const submissionsCollectionRef = collection(challengeDocRef, "submissions");
      // Add a new submission document
      const docRef = await addDoc(submissionsCollectionRef, submissionData);
      // Update the challenge document to increment participants count
      await updateDoc(challengeDocRef, {
        participants: increment(1)
      });
      setSubmissionData(submissionSchema);
      dispatch(setSnackbar(true, "success", "Submission made successfully!"));
      setIsSelected(false);
      setSubmiting(false);
      history.goBack();
    } catch (error) {
      dispatch(setSnackbar(true, "error", "Submission failed!")); // Use "error" type
      setSubmiting(false);
      console.error("Error adding submission: ", error); 
    }
  };
  return (
    <Grid item container xs={8} md={6} className={classes.paper}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              alignItem: "center",
              marginBottom: "20px",
            }}
          >
            <ArrowBackIcon
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            />
            <Typography className={classes.header}>SUBMISSION</Typography>
          </div>
          {/* <hr /> */}
          <div className={classes.commentContainer}>
            <div className={classes.fileInputContainer}>
              {isSelected ? (
                <div style={{ marginLeft: "10px" }}>
                  <Typography className={classes.fileDetails}>
                    File name: {selectedFile.name}{" "}
                    <span
                      style={{ marginLeft: "15px" }}
                    >{`upload is ${percent} % done`}</span>
                  </Typography>
                  <Button disabled={percent < 100} onClick={handleRemoveFile}>
                    Remove file
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                >
                  <img
                    height={50}
                    width={50}
                    src="/assets/upload.svg"
                    alt=""
                    style={{ marginBottom: "10px" }}
                  />
                  <Typography
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "132.24%",
                      color: "#180E40",
                      marginBottom: "10px",
                    }}
                  >
                    Click here to choose a file
                    {/* <span style={{ color: "#AFB7C4" }}>drag it here</span> */}
                  </Typography>
                  {/* <Typography
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "132.24%",
                      color: "#AFB7C4",
                    }}
                  >
                    Files should be in pdf, docs or jpeg
                  </Typography> */}
                  <input
                    type="file"
                    ref={uploadInputRef}
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div>
            <InputBase
              multiline
              placeholder="Comment(optional)"
              minRows={4}
              maxRows={4}
              value={submissionData.comment}
              className={classes.commentInput}
              onChange={handleCommentChange}
            />

            {/* Displaying additional challenge sections */}
            {challenge.sections?.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <Typography className={classes.inputLabel}>
                  {section.label}
                </Typography>
                <section className={classes.commentInput}>
                  {section.fields?.map((field, fieldIndex) => (
                    <div key={fieldIndex}>
                      <Typography className={classes.inputLabel}>
                        {field.label}
                      </Typography>
                      {field.type === "boolean" ? (
                        <select
                          name={field.label}
                          required
                          value={addedSections[field.label]}
                          onChange={(event) =>
                            handleAddedSectionsValueChange(
                              event,
                              sectionIndex,
                              fieldIndex,
                              section.label
                            )
                          }
                        >
                          <option value="">select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      ) : (
                        <InputBase
                          type={field.type}
                          fullWidth={field.type === "text" ? true : false}
                          multiline={field.type === "text" ? true : false}
                          name={field.label}
                          required
                          value={addedSections[field.label]}
                          onChange={(event) =>
                            handleAddedSectionsValueChange(
                              event,
                              sectionIndex,
                              fieldIndex,
                              section.label
                            )
                          }
                          placeholder={
                            field.type === "text"
                              ? `enter ${field.label.toLowerCase()}`
                              : ""
                          }
                          // minRows={4}
                          maxRows={4}
                          className={classes.textInput}
                        />
                      )}
                    </div>
                  ))}
                </section>
              </div>
            ))}
            {/* Displaying additional challenge fields */}
            {challenge.fields?.map((field, index) => (
              <div key={index}>
                <Typography className={classes.inputLabel}>
                  {field.label}
                </Typography>
                <div>
                  {field.type === "boolean" ? (
                    <select
                      name={field.label}
                      required
                      value={submissionData.fields[field.label]}
                      onChange={(event) =>
                        handleAddedFieldsValueChange(index, event)
                      }
                    >
                      <option value="">select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  ) : (
                    <InputBase
                      type={field.type}
                      required
                      fullWidth={field.type === "text" ? true : false}
                      multiline={field.type === "text" ? true : false}
                      name={field.label}
                      placeholder={
                        field.type === "text"
                          ? `enter ${field.label.toLowerCase()}`
                          : ""
                      }
                      // minRows={4}
                      maxRows={4}
                      className={classes.textInput}
                      value={submissionData.fields[field.label]}
                      onChange={(event) =>
                        handleAddedFieldsValueChange(index, event)
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* here */}
          <div className={classes.btnContainer}>
            <Button
              disableRipple
              disabled={percent < 100 && isSelected && !submiting}
              type="submit"
              className={classes.submitBtn}
              variant="outlined"
            >
              {submiting ? "sending..." : "Submit"}
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default MakeSubmission;
