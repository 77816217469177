import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "../../components/Appbar";
import Footer from "../../components/Footer";
// import Home from "./Home";
import Form from "./Form";
// import { useStep } from "react-hooks-helper";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));
// const steps = [{ id: "home" }, { id: "form" }];

export default function Signup() {
  const classes = useStyles();

  // const { step, navigation } = useStep({ initialStep: 0, steps });
  // props = { navigation };
  // const { id } = step;

  // const newProps = { navigation };

  // const renderSwitch = (id) => {
  //   switch (id) {
  //     case "form":
  //       return <Form {...newProps} />;

  //     default:
  //       return <Home {...newProps} />;
  //   }
  // };

  return (
    <div>
      <div>
        <AppBar />
      </div>
      <div className={classes.content}>
        <Form />
      </div>
      {/* <div className={classes.content}>{renderSwitch(id)}</div> */}
      <Footer />
    </div>
  );
}
