import React from "react";
import ProductForm from "../components/ProductForm";

const Step3 = ({ navigation, formState, setFormState }) => {
  const step = 3;
  const newProps = { navigation, formState, setFormState, step };
  return <ProductForm {...newProps} />;
};

export default Step3;
