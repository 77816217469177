import React, { useState, useEffect } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Success from "../components/Success";
import { useStep } from "react-hooks-helper";
import validate from "validate.js";

const steps = [{ id: "personal" }, { id: "product" }, { id: "success" }];

export default function Individual() {
  const { step, navigation } = useStep({ initialStep: 0, steps });
  // props = { navigation };
  const { id } = step;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const newProps = { navigation, formState, setFormState };

  const renderSwitch = (id) => {
    switch (id) {
      case "personal":
        return <Step1 {...newProps} />;
      case "product":
        return <Step2 {...newProps} />;
      case "success":
        return <Success {...newProps} />;
      default:
        return null;
    }
  };

  // const [value, setValue] = useState();

  return <>{renderSwitch(id)}</>;
}
