import React, { useEffect, useState } from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { db } from "../../../store/firebase/firebase";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TodayIcon from "@material-ui/icons/Today";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "#fff",
    boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
    padding: theme.spacing(2),
  },

  desc: {
    color: "rgba(132, 142, 156, 1)",
  },
  inline: {
    display: "flex",
  },
  inlineSpacer: {
    display: "flex",
    marginBlock: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(1),
    color: "#EA4C89",
  },
  title: {
    color: "#180E40",
    fontWeight: "bold",
  },
}));

const About = (props) => {
  const classes = useStyles();
  const { about } = props;

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={9}>
        <Typography gutterBottom variant="h5">
          About
        </Typography>
        <Typography className={classes.desc}>
          {about && about.bio}
        </Typography>
      </Grid>
      <Grid item sm={12} md={3}>
        <div className={classes.card}>
          <div className={classes.inlineSpacer}>
            <LocationOnIcon fontSize="small" className={classes.icons} />
            <Typography gutterBottom>{about && about.city, + " "+ about && about.country}</Typography>
          </div>
          <div className={classes.inlineSpacer}>
            <TodayIcon fontSize="small" className={classes.icons} />
            <Typography gutterBottom>Members since</Typography>
          </div>
        </div>

        {/* <Typography variant="h5" className={classes.title}>Skills</Typography>
        <Typography variant="h5" className={classes.title}>Spaces</Typography> */}
      </Grid>
    </Grid>
  );
};

export default About;
