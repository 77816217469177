import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
// Rendering individual images
const Image = ({ image }) => {
  return (
    <img
      alt={`img - ${image.id}`}
      src={image.src}
      className="file-img"
      style={{ maxWidth: "100%" }}
    />
  );
};

// ImageList Component
const ImageList = ({ images }) => {
  // render each image by calling Image component
  const renderImage = (image, index) => {
    return (
      <Grid item sm={4}>
        <div
          style={{
            position: "relative",
            border: "1px dashed #ABB3BF",
            borderRadius: 5,
          }}
        >
          <IconButton style={{ position: "absolute", right: 1, top: 1 }}>
            <CancelIcon />
          </IconButton>
          <Image image={image} key={`${image.id}-image`} />
        </div>
      </Grid>
    );
  };

  // Return the list of files
  return (
    <Grid container spacing={2} alignItems="stretch">
      {images.map(renderImage)}
    </Grid>
  );
};

export default ImageList;
