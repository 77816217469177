import {SET_SNACKBAR} from "../actions/snackBar"
const initialState = {
  open: false,
  severity: "",
  message: "",
};
 const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { open, severity, message } = action;
      return {
        ...state,
        open,
        severity,
        message,
      };
    default:
      return state;
  }
};

export default snackbar
