import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountriesInput from "../../../components/AfricanCountriesInput";
import ProfileImageUpload from "../../../components/ProfileUpload";
import validate from "validate.js";
import Select from "react-select";
import countryList from "react-select-country-list";

const schema = {
  businessName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  bio: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  profilePhoto: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  steps: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(3),
    color: "#605A5A",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: "rgba(24, 14, 64, 1)",
    marginBottom: theme.spacing(4),
  },
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    paddingInline: theme.spacing(4),
    backgroundColor: "rgba(24, 14, 64, 1)",
    color: "#fff",
    "&$disabled": {
      background: "#fff",
      color: "rgba(24, 14, 64, 1)",
      border: "1px solid rgba(24, 14, 64, 1)",
    },
  },
  disabled: {},
}));

export default function BusinessStep1({ navigation, formState, setFormState }) {
  const classes = useStyles();

  const { next } = navigation;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, []);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleOnBlur = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        phoneNumber: value,
      },
    }));
  };

  const chosenCountry = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        country: value,
      },
    }));
  };

  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        country: value.label,
      },
    }));
  };

  //const [value, setValue] = useState();
  const profileCallback = (url) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        profilePhoto: url,
      },
    }));
  };

  return (
    <div>
      <Typography gutterBottom className={classes.steps}>
        Step 1 <span style={{ fontWeight: 500 }}>of 3</span>
      </Typography>
      <Typography gutterBottom className={classes.title}>
        Business Details
      </Typography>

      <Typography gutterBottom className={classes.label}>
        Phone number
      </Typography>

      <div className={classes.field}>
        <PhoneInput
          name="phoneNumber"
          inputStyle={{
            width: "100%",
          }}
          country={"gh"}
          value={formState.values.phoneNumber}
          onBlur={(e) => handleOnBlur(e.target.value)}
        />
      </div>

      <Typography gutterBottom className={classes.label}>
        Country
      </Typography>
      <div className={classes.field}>
        <Select
          options={options}
          value={value}
          isSearchable
          onChange={changeHandler}
        />
      </div>

      <Typography gutterBottom className={classes.label}>
        City
      </Typography>
      <TextField
        name="city"
        value={formState.values.city}
        onChange={handleChange}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
      />

      <Typography gutterBottom className={classes.label}>
        Address
      </Typography>
      <TextField
        name="address"
        value={formState.values.address}
        onChange={handleChange}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
      />

      <Typography gutterBottom className={classes.label}>
        Bio
      </Typography>
      <TextField
        name="bio"
        value={formState.values.bio}
        onChange={handleChange}
        className={classes.field}
        fullWidth
        multiline
        variant="outlined"
        size="small"
        rows={10}
      />

      <Typography gutterBottom className={classes.label}>
        Upload company logo (JPEG, PNG)
      </Typography>
      <ProfileImageUpload profileCallback={profileCallback} />

      <Button
        className={classes.button}
        classes={{
          root: classes.button,
          disabled: classes.disabled,
        }}
        disabled={!formState.isValid}
        variant="contained"
        onClick={next}
      >
        Next
      </Button>
    </div>
  );
}
