import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "50px",
    cursor: "pointer",
  },
  imgContainer: {
    background: "#FFFFFF",
    maxHeight: "200px",
    // border: "1px solid #CDD3DC",
    // boxShadow: "inset 0px -1px 0px rgba(208, 201, 214, 0.4)",
    // borderRadius: "6.01307px",
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#252D39",
    marginBottom: "8px",
  },
  date: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#252D39",
  },
  deadlineTxt: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#848E9C",
    margin: "0px 8px",
  },
  description: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    color: "#252D39",
  },
}));

export default function ChallengeCard(props) {
  const classes = useStyles();
  const { id, title, deadline, description, imgUrl } = props;
  const history = useHistory();

  const truncate = (str) => {
    return str.length < 300 ? str : str.substring(0, 300) + "...";
  };
  return (
    <Grid
      item
      container
      key={id}
      spacing={4}
      className={classes.container}
      onClick={() => history.push(`/challenge-details/${id}`)}
    >
      <Grid item xs={4} md={2} className={classes.imgContainer}>
        <img width="100%" height="100%" src={imgUrl} alt="" style={{borderRadius:"4px"}} />
      </Grid>
      <Grid item xs={10} md={8}>
        <Typography variant="h4" style={{ fontWeight: 700 }}gutterBottom>{title}</Typography>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <img src="./assets/Date_range.svg" alt="" />
          <Typography variant="h6">
            <span className={classes.deadlineTxt}>Deadline</span> {new Date(deadline).toDateString()}
          </Typography>
        </div>
        <Typography variant="h5">
          {truncate(description)}
        </Typography>
      </Grid>
    </Grid>
  );
}
