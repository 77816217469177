import React from "react";
import { Typography, IconButton, Divider } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

// Rendering individual products
const Product = ({ product, productIndex, handleRemoveInput, handleEdit }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography style={{ color: "#333", fontWeight: 700, fontSize: 16 }}>
        {product.name}
      </Typography>

      <div>
        <IconButton onClick={() => handleEdit(product, productIndex)}>
          <CreateIcon fontSize="small" style={{ color: "#460CEB" }} />
        </IconButton>
        <IconButton  onClick={() => handleRemoveInput(productIndex)}>
          <DeleteIcon fontSize="small" style={{ color: "#460CEB" }} />
        </IconButton>
      </div>
    </div>
  );
};

// ProductList Component
const ProductList = ({ products, handleRemoveInput, handleEdit }) => {
  // render each product by calling Product component
  const renderProduct = (product, index) => {
    return <Product product={product} key={`${product.id}-product`} productIndex={index} handleRemoveInput={handleRemoveInput} handleEdit={handleEdit}/>;
  };

  // Return the list of products
  return (
    <div>
      {products.map(renderProduct)}
      <Divider style={{ color: "#E7E9EE", marginBottom: 30 }} />
    </div>
  );
};

export default ProductList;
