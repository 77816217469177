import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "../../components/Appbar";
import Footer from "../../components/Footer";
import Business from "./Business";
import Individual from "./Individual";
import { Grid, Typography, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    // backgroundColor: "#E3E8EC",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  header: {
    textAlign: "center",
    marginTop: "4%",
    marginBottom: "4%",
  },
  paper: {
    marginBlock: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "36px",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "30px",
  },
}));

function CreateProfile(props) {
  const classes = useStyles();

  const account = localStorage.getItem("account");

  const { isSignedUp } = props;
  console.log("isSignedUp: ", isSignedUp);

  const renderSwitch = (account) => {
    switch (account) {
      case "individual":
        return <Individual />;
      case "business":
        return <Business />;
      default:
        return null;
    }
  };

  // const renderTextSwitch = (account) => {
  //   switch (account) {
  //     case "individual":
  //       return "Individual";
  //     case "business":
  //       return "Business";
  //     default:
  //       return null;
  //   }
  // };

  return isSignedUp ? (
    <div>
      <AppBar page="createProfile" />
      <Grid container className={classes.content} justify="center">
        <Grid item sm={12} md={8} lg={6}>
          <Typography gutterBottom className={classes.title}>
            Complete profile
          </Typography>
          <Typography gutterBottom className={classes.subTitle}>
            Please fill in the details below to create your profile
          </Typography>
          <Paper className={classes.paper}>{renderSwitch(account)}</Paper>
        </Grid>
      </Grid>

      <Footer />
    </div>
  ) : (
    <Redirect to="/" />
  );
}

CreateProfile.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    error: state.auth.error,
    isSignedUp: state.auth.isSignedUp,
  };
}

export default connect(mapStateToProps)(CreateProfile);
