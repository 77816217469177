import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import Appbar from "../../components/Appbar";
import Footer from "../../components/Footer";
import { Skeleton } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { NavHashLink as NavLink } from "react-router-hash-link";
import Link from "@material-ui/core/Link";
import { db } from "../../store/firebase/firebase";
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    width: "100%",
    background: "#F4F7FB",
    padding: "50px 10% 40px 5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 3% 40px",
    },
  },
  challengeInfoHeading: {
    fontWeight: 300,
    fontSize: "1rem",
    lineHeight: "132.24%",
    color: "#252D39",
    marginBottom: "10px",
  },
  challengeInfoDetails: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "132.24%",
    color: "#252D39",
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "132.24%",
    color: "#252D39",
    margin: "16px 0px",
  },
  body: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    color: "#252D39",
    margin: "16px 0px",
  },
  sideLinkActive: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    color: "#252D39",
    margin: "16px 0px",
  },
  sideLink: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "132.24%",
    color: "#AFB7C4",
    margin: "16px 0px",
    textDecoration: "none",
  },
  makeSubmitionBtn: {
    // width: "170px",
    color: "#fff",
    fontWeight: 400,
    fontSize: "16px",
    backgroundColor: "#180E40",
    borderRadius: "3px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#180E40",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 10px",
    },
  },
  detailSection: {
    padding: "50px 10% 10% 5%",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 5% 20%",
    },
  },
  sideNav: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ChallengeDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [challenge, setChallenge] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    const fetchChallenge = async () => {
      try {
        const challengeDocRef = doc(db, "challenges", id);
        const docSnapshot = await getDocs(challengeDocRef);
        if (docSnapshot.exists()) {
          setChallenge(docSnapshot.data());
        } else {
          console.log("No such challenge!");
        }
      } catch (error) {
        console.error("Error fetching challenge:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchChallenge();
  }, [id]);
  // ... rest of your component logic

  return (
    <>
      <Appbar />

      <Grid container xs={12}>
        <div className={classes.heroContainer}>
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/challenges")}
          />
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "132.24%",
              color: "#252D39",
              margin: "24px 0px 32px",
            }}
          >
            {challenge.title}
          </Typography>

          <Grid item xs={12} style={{ display: "flex" }}>
            {challenge && (
              <div style={{ display: "flex", flexGrow: 1 }}>
                <div style={{ marginRight: "25px" }}>
                  <Typography className={classes.challengeInfoHeading}>
                    Timeline
                  </Typography>
                  <Typography className={classes.challengeInfoDetails}>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      new Date(challenge.endDate).toDateString()
                    )}
                  </Typography>
                </div>
                <div style={{ marginRight: "25px" }}>
                  <Typography className={classes.challengeInfoHeading}>
                    Prize
                  </Typography>
                  <Typography className={classes.challengeInfoDetails}>
                    {challenge.price}
                  </Typography>
                </div>
                <div style={{ marginRight: "25px" }}>
                  <Typography className={classes.challengeInfoHeading}>
                    Participant
                  </Typography>
                  <Typography className={classes.challengeInfoDetails}>
                    {challenge.participants}
                  </Typography>
                </div>
              </div>
            )}

            <Button
              disableRipple
              className={classes.makeSubmitionBtn}
              onClick={() => history.push(`/make-submission/${id}`)}
            >
              Make a submission
            </Button>
          </Grid>
        </div>
        {/* Main details section */}
        <Grid item container xs={12} className={classes.detailSection}>
          {/* side navigation */}
          <Grid item md={2} className={classes.sideNav}>
            <NavLink
              to="#description"
              className={classes.sideLink}
              // activeClassName={classes.sideLinkActive}
              activeStyle={{
                fontWeight: 300,
                fontSize: "16px",
                lineHeight: "132.24%",
                color: "#252D39",
                margin: "16px 0px",
              }}
            >
              Description
            </NavLink>
            <NavLink to="#rules" className={classes.sideLink}>
              Rules
            </NavLink>
            <NavLink to="#timeline" className={classes.sideLink}>
              Timeline
            </NavLink>
            <Link href="#prizes" className={classes.sideLink}>
              Prizes
            </Link>
          </Grid>

          {/* Details */}
          <Grid item xs={12} md={10}>
            <div id="description" style={{ marginBottom: "40px" }}>
              <Typography className={classes.title}>Description</Typography>
              <Typography className={classes.body}>
                {challenge.description}
              </Typography>
            </div>
            <div id="rules" style={{ marginBottom: "40px" }}>
              <Typography className={classes.title}>Rules</Typography>
              <Typography className={classes.body}>
                {challenge.rules}
              </Typography>
            </div>
            <div id="timeline" style={{ marginBottom: "40px" }}>
              <Typography className={classes.title}>Timeline</Typography>
              <Typography className={classes.body}>
                {loading ? (
                <Skeleton variant="text" width="100px" />) : ( new
                Date(challenge.endDate).toDateString() )}
              </Typography>
            </div>
            <div id="prizes" style={{ marginBottom: "40px" }}>
              <Typography className={classes.title}>Prizes</Typography>
              <Typography className={classes.body}>
                {challenge.price}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <Modal open={modalOpen}>
        <MakeSubmission
          handleClose={handleClose}
          id={id}
          challengeTitle={challenge.title}
          fields={challenge.fields}
        />
      </Modal> */}

      <Footer />
    </>
  );
};

export default ChallengeDetails;
