import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import RoomIcon from '@material-ui/icons/Room';
// import {} from "../../../svgs/reading.svg"
const MapView = (props) => {
  const mapCenter = { lat: 0, lng: 0 }; // Default center
const numberOfMarkers = (city) => {
  if(props.markers){
    const marker = props.markers.filter((marker) => marker.city.toUpperCase() === city.toUpperCase());
 
    if (marker) {
      return marker.length?.toString()
    }
    
  }
  return
}
  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
        <GoogleMap
          mapContainerStyle={{ height: "100vh", width: "100%" }}
          center={props.bounds ? props.bounds.getCenter().toJSON() : mapCenter}
          // zoom={props.bounds ? null : 2}
          zoom={props.mapZoom}
          bounds={props.bounds}
        >
          {props.markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.coordinates}
              title={marker.city}
              optimized={false}
              // draggable={true}
              options={{
                label: numberOfMarkers(marker.city)
              }}
              animation={window.google.maps.Animation.DROP}
            >
            </Marker>
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};
export default React.memo(MapView);
