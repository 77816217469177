import React, {  useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validate from "validate.js";

const schema = {
  contactName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  contactEmail: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  contactPhone: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  steps: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(3),
    color: "#605A5A",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: "rgba(24, 14, 64, 1)",
    marginBottom: theme.spacing(4),
  },
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    paddingInline: theme.spacing(4),
    backgroundColor: "rgba(24, 14, 64, 1)",
    color: "#fff",
    "&$disabled": {
      background: "#fff",
      color: "rgba(24, 14, 64, 1)",
      border: "1px solid rgba(24, 14, 64, 1)",
    },
  },
  disabled: {},
}));

export default function BusinessStep2({ navigation, formState, setFormState }) {
  const classes = useStyles();

  const { previous, next } = navigation;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, []);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleOnBlur = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        contactPhone: value,
      },
    }));
  };

  //const [value, setValue] = useState();

  return (
    <div>
      <Typography gutterBottom className={classes.steps}>
        Step 2 <span style={{ fontWeight: 500 }}>of 3</span>
      </Typography>
      <Typography gutterBottom className={classes.title}>
        Contact Person
      </Typography>

      <Typography gutterBottom className={classes.label}>
        Name
      </Typography>
      <TextField
        name="contactName"
        value={formState.values.contactName}
        onChange={handleChange}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
      />

      <Typography gutterBottom className={classes.label}>
        Email
      </Typography>
      <TextField
        name="contactEmail"
        type="email"
        value={formState.values.contactEmail}
        onChange={handleChange}
        className={classes.field}
        fullWidth
        variant="outlined"
        size="small"
      />

      <Typography gutterBottom className={classes.label}>
        Phone number
      </Typography>

      <div className={classes.field}>
        <PhoneInput
          name="contactPhone"
          inputStyle={{
            width: "100%",
          }}
          country={"gh"}
          value={formState.values.contactPhone}
          onBlur={(e) => handleOnBlur(e.target.value)}
        />
      </div>

      <div style={{ marginTop: 30 }}>
        <Button
          className={classes.button}
          classes={{
            root: classes.button,
            disabled: classes.disabled,
          }}
          variant="contained"
          onClick={next}
        >
          Next
        </Button>

        <Button
          variant="text"
          style={{ color: "rgba(24, 14, 64, 1)" }}
          onClick={previous}
        >
          Previous
        </Button>
      </div>
    </div>
  );
}
