import React, { useState, useEffect } from "react";
import Step1 from "./Step1";
import { db } from "../../../store/firebase/firebase";
import Success from "../components/Success";
import { useStep } from "react-hooks-helper";
import validate from "validate.js";
import { doc, getDoc } from "firebase/firestore";

const steps = [{ id: "personal" }, { id: "success" }];

export default function Individual() {
  const { step, navigation } = useStep({ initialStep: 0, steps });
  // props = { navigation };
  const { id } = step;
  const userId = localStorage.getItem("user_id");

  const [responseData, setResponseData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (userId) {
          const userDocRef = doc(db, "users", userId);
          const docSnapshot = await getDoc(userDocRef);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setResponseData(userData);
            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                phoneNumber: userData.phoneNumber,
                bio: userData.bio,
                country: userData.country,
                city: userData.city,
                profilePhoto: userData.profilePhoto
              },
            }));
          } else {
            console.log("No such User!");
          }
        }
      } catch (error) {
        console.error("Error getting user:", error);
      }
    };
    fetchUserData();
  }, [userId]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const newProps = { navigation, formState, setFormState, responseData };

  const renderSwitch = (id) => {
    switch (id) {
      case "personal":
        return <Step1 {...newProps} />;
      case "success":
        return <Success {...newProps} />;
      default:
        return null;
    }
  };

  // const [value, setValue] = useState();

  return <>{renderSwitch(id)}</>;
}
