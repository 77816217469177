export const ADD_HIDE_INVOICETABLE_LABEL = "ADD_HIDE_INVOICETABLE_LABEL";
export const ADD_INVOICETABLE_BACK_COLOR = "ADD_INVOICETABLE_BACK_COLOR";
export const ADD_INVOICETABLE_BORDER_COLOR = "ADD_INVOICETABLE_BORDER_COLOR";
export const ADD_INVOICETABLE_ALIGN = "ADD_INVOICETABLE_ALIGN";
export const ADD_INVOICETABLE_TAX = "ADD_INVOICETABLE_TAX";
export const ADD_FILE_UPLOAD_LAYOUT = "ADD_FILE_UPLOAD_LAYOUT";
export const ADD_FILE_UPLOAD_COLOR = "ADD_FILE_UPLOAD_COLOR";
export const ADD_HIDE_LABEL = "ADD_HIDE_LABEL";
export const ADD_LABEL_NAME = "ADD_LABEL_NAME";
export const ADD_LABEL_NAME_TEXT_STYLE = "ADD_LABEL_NAME_TEXT_STYLE";
export const ADD_LABEL_NAME_TEXT_NAME = "ADD_LABEL_NAME_TEXT_NAME";
export const ADD_LABEL_NAME_TEXT_COLOR = "ADD_LABEL_NAME_TEXT_COLOR";
export const ADD_FILE_PREFERENCE_TEXT = "ADD_FILE_PREFERENCE_TEXT";
export const ADD_FILE_UPLOAD_FROM = "ADD_FILE_UPLOAD_FROM";
export const ADD_LABEL_NAME_TEXT_SIZE = "ADD_LABEL_NAME_TEXT_SIZE";
export const ADD_HIDE_TOOL_TIP = "ADD_HIDE_TOOL_TIP";
export const ADD_FILE_TOOLTIP_TEXT = "ADD_FILE_TOOLTIP_TEXT";
export const UPDATE_PROP = "UPDATE_PROP";
export const ACTIVATE_CANVAS = "ACTIVATE_CANVAS";
export const ADD_HIDE_INVOICE_LABEL = "ADD_HIDE_INVOICE_LABEL";
export const SAVE_CANVAS = "ACTIVATE_CANVAS";
export const ALL_QUESTIONS = "ALL_QUESTIONS";
export const USER_QUESTIONS = "USER_QUESTIONS";
export const ALL_COMMENTS = "ALL_COMMENTS";
export const QUESTIONS_COMMENTS = "QUESTIONS_COMMENTS"
export const REPLY = "REPLY";
export const SHARE = "SHARE"