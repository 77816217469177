import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Link, Grid, Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallIcon from "@material-ui/icons/Call";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { ReactComponent as Logo } from "../svgs/meethub.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(2, 5),
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    color: "#180E40",
  },
  menuItem: {
    cursor: "pointer",
    marginBottom: theme.spacing(1.5),
  },
  menuLink: {
    color: "#180E40",
  },
  socials: {
    color: "#180E40",
  },
  icon: {
    color: "#180E40",
    marginRight: theme.spacing(2),
  },
  address: {
    display: "flex",
    marginBottom: theme.spacing(2),
    color: "180E40",
  },
  content: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  textColor: {
    color: "#180E40",
  },
  bio: {
    // color: "#848E9C",
    marginTop: 16,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={6} md={3}>
        <Grid item container spacing={1} alignItems="center">
          <Grid item sm={4}>
            <Logo style={{width: "100%"}}/>
          </Grid>
          <Grid item sm={4}>
            <img
              src="../../assets/hubiquitousLogo.png"
              style={{ width: "100%" }}
              alt=""
            />
          </Grid>
          <Grid item sm={4}>
            <img src="../../assets/eu.jpeg" style={{ width: "50%" }} alt="EU" />
          </Grid>
        </Grid>
        <Typography className={classes.bio} gutterBottom>
          Meethub is an IoT community that thrives on networking. It was borne
          out of the Hubiquitous project which has received funding from the
          European Union’s Horizon 2020 research and innovation programme under
          grant agreement number 101016895.
        </Typography>
        <div>
          <IconButton className={classes.socials} style={{ paddingLeft: 0 }}>
            <InstagramIcon fontSize="small" />
          </IconButton>
          <IconButton className={classes.socials}>
            <FacebookIcon fontSize="small" />
          </IconButton>
          <IconButton className={classes.socials}>
            <LinkedInIcon fontSize="small" />
          </IconButton>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3} className={classes.menuList}>
        <div className={classes.content}>
          <Typography className={classes.menuItem}>
            <Link className={classes.menuLink} style={{ fontWeight: "bold" }}>
              About Meethub
            </Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link href="/" className={classes.menuLink}>
              Developer
            </Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link href="/" className={classes.menuLink}>
              Startup
            </Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link className={classes.menuLink}>DIH</Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link className={classes.menuLink}>Terms of service</Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link className={classes.menuLink}>Privacy policy</Link>
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3} className={classes.menuList}>
        <div className={classes.content}>
          <Typography className={classes.menuItem}>
            <Link
              href="/connect"
              className={classes.menuLink}
              style={{ fontWeight: "bold" }}
            >
              Connect
            </Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link href="/" className={classes.menuLink}>
              Learn
            </Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link href="/" className={classes.menuLink}>
              Challenges
            </Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link className={classes.menuLink}>Jobs</Link>
          </Typography>
          <Typography className={classes.menuItem}>
            <Link className={classes.menuLink}>Hire an Engineer</Link>
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Typography
          gutterBottom
          className={classes.menuLink}
          style={{ fontWeight: "bold" }}
        >
          Contact
        </Typography>

        <div className={classes.address}>
          <LocationOnOutlinedIcon className={classes.icon} fontSize="small" />
          <div>
            <Typography className={classes.textColor}>
              CLOUDPORT
            </Typography>
            <Typography className={classes.textColor}>
              Nikoi Street
            </Typography>
            <Typography className={classes.textColor}>
              East Legon, Accra
            </Typography>
            <Typography className={classes.textColor}>
              Accra/Ghana
            </Typography>
          </div>
        </div>

        {/* <div className={classes.address}>
          <EmailOutlinedIcon className={classes.icon} fontSize="small" />
          <Typography className={classes.textColor}>
            hello@meethub.com
          </Typography>
        </div> */}
        <div className={classes.address}>
          <CallIcon className={classes.icon} fontSize="small" />
          <Typography className={classes.textColor}>
            +233 302 953 022
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
