import React, { useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Avatar,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { db } from "../../../store/firebase/firebase";
import { useHistory } from "react-router-dom";
import ConnectProfileDetails from "./ConnectProfileDetails";
import { useStep } from "react-hooks-helper";
import ReactPagination from "react-paginate";
import MapView from "./MapView";
import { collection } from "firebase/firestore";
import { getDocs } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    background: "#fff",
    boxShadow: "0px 7px 64px 0px rgba(0, 0, 0, 0.07)",
    padding: theme.spacing(3, 1),
    height: "100%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
  },
  showingResults: {
    marginTop: "35px",
    // fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    // lineHeight: "17px",
    color: "#485465",
  },
  paginationContainer: {
    listStyle: "none",
    display: "flex",
    marginTop: "35px",
    "& a": {
      border: "1px solid #ABB3BF",
      boxSizing: "border-box",
      borderRadius: "8px",
      padding: "10px 15px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      margin: "0px 2px",
      "&:hover": {
        background: "#180E40",
        color: "#fff",
      },
    },
  },
  paginationActive: {
    background: "#180E40",
    color: "#fff",
    border: "none",
  },
  disabledBtn: {
    color: "#ABB3BF",
  },
}));

const Profiles = (props) => {
  const classes = useStyles();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const postsPerPage = 24;
  const history = useHistory();

  const [responseData, setResponseData] = useState([]);
  const [fullResponse, setFullResponse] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [activateSearch, setActivateSearch] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [mapZoom, setMapZoom] = useState(2);
  const steps = [{ id: "profile" }, { id: "details" }];

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const { next } = navigation;
  const [userId, setUserId] = useState("");
  const [markers, setMarkers] = useState([]);
  const [cardItems, setCardItems] = useState([]);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollectionRef = collection(db, "users");
        const querySnapshot = await getDocs(usersCollectionRef);
        if (querySnapshot.empty) {
          setEmpty(true);
        } else {
          const fullData = [];
          const reportData = [];
          const citiesData = [];
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            citiesData.push(userData.city);
            fullData.push({
              id: doc.id,
              name: userData.name ?? `${userData.firstName} ${userData.lastName}`,
              imgUrl: userData.profileImage,
              briefProfile: userData.bio,
              city: userData.city,
              country: userData.country,
              accountType: userData.account,
            });
            if (userData.account === props.userType) {
              reportData.push({
                id: doc.id,
                name: userData.name ?? `${userData.firstName} ${userData.lastName}`,
                imgUrl: userData.profileImage,
                briefProfile: userData.bio,
                city: userData.city,
                country: userData.country,
              });
            }
          });
          setActivateSearch(false);
          setEmpty(false);
          setResponseData(reportData);
          setCardItems(reportData);
          setFullResponse(fullData);
          setCities(citiesData);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, [props.userType])

  useEffect(() => {
    const fetchMarkers = async () => {
      const markerData = await Promise.all(
        cardItems.map(async (user) => {
          const coordinates = await fetchCoordinates(user.city);
          return { city: user.city, coordinates };
        })
      );

      setMarkers(markerData.filter((marker) => marker.coordinates));
      // setMapZoom(4);
    };

    fetchMarkers();
  }, [cardItems]);

  const fetchCoordinates = async (city) => {
    const apiKey = process.env.REACT_APP_MAP_API_KEY;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      city
    )}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error(`No results found for ${city}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
      return null;
    }
  };

  const offSet = currentPageNumber * postsPerPage;
  const currentPost = useMemo(() => {
    return cardItems.slice(offSet, offSet + postsPerPage);
  }, [offSet, postsPerPage, cardItems]);

  const totalPosts = cardItems.length;
  const totalNumberOfPages = Math.ceil(totalPosts / postsPerPage);

  const paginate = ({ selected: selectedPage }) => {
    setCurrentPageNumber(selectedPage);
  };

  const handleCallbackForId = (value) => {
    setUserId(value);
    next();
  };

  const handleSearch = async ({ city, country, name, accountType }) => {
    console.debug({ city, country, name, accountType });
    let lists = [...responseData];
    const formData = { city, country, name, accountType };
    const searchKeys = Object.keys(formData);

    searchKeys.forEach((key) => {
      if (formData[key] !== "") {
        if (key === "name") {
          lists = lists.filter((user) =>
            user[key]?.toUpperCase().includes(formData[key]?.toUpperCase())
          );
        } else {
          formData[key] = formData[key]?.toUpperCase();
          lists = lists.filter(
            (user) => user[key]?.toUpperCase() === formData[key]?.toUpperCase()
          );
        }
      }
    });

    // set card items that should be rendered in the View
    setCardItems(lists);

    try {
      const markerData = await Promise.all(
        lists.map(async (user) => {
          const coordinates = await fetchCoordinates(user.city);
          return { city: user.city, coordinates };
        })
      );
      const filteredMarkers = markerData.filter((marker) => marker.coordinates);
      setMarkers(filteredMarkers);
      setMapZoom(4);
      // Update bounds to include the markers in the searched location
      const newBounds = new window.google.maps.LatLngBounds();
      filteredMarkers.forEach((marker) => {
        newBounds.extend(marker.coordinates);
      });
      setBounds(newBounds);
    } catch (error) {}
  };

  const renderSwitch = (id) => {
    switch (id) {
      case "profile":
        return (
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" width={"60%"} pr={2}>
              <Box
                style={{ backgroundColor: "#ffffff" }}
                mb={2}
                p={2}
                borderRadius={"8px"}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const city = e.target.elements.city.value;
                    const country = e.target.elements.country.value;
                    const accountType = e.target.elements.accountType.value;
                    const name = e.target.elements.name.value;
                    handleSearch({ city, country, name, accountType });
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ gap: "10px" }}
                    // mb={3}
                  >
                    <TextField
                      type="text"
                      name="city"
                      variant="outlined"
                      size="small"
                      placeholder="Enter city"
                    />
                    <TextField
                      type="text"
                      name="country"
                      variant="outlined"
                      size="small"
                      placeholder="Enter country"
                    />
                    <TextField
                      type="text"
                      name="accountType"
                      variant="outlined"
                      size="small"
                      placeholder="Enter account type ex. individual"
                    />
                    <TextField
                      type="text"
                      name="name"
                      variant="outlined"
                      size="small"
                      placeholder="Enter name"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ borderRadius: "20px" }}
                    >
                      Search
                    </Button>
                  </Box>
                </form>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  {currentPost
                    .filter((item) => {
                      if (!textValue) return true;
                      if (
                        item.name.includes(textValue) ||
                        item.briefProfile.includes(textValue)
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .filter((item) => {
                      if (!selectedCountry) return true;
                      if (item.country.includes(selectedCountry)) {
                        return true;
                      }
                      return false;
                    })
                    .map((individual, index) => (
                      <Grid
                        item
                        sm={4}
                        md={4}
                        key={individual.id}
                        onClick={() => handleCallbackForId(individual.id)}
                      >
                        <div className={classes.cardContent}>
                          <div className={classes.center}>
                            <Avatar
                              src={individual.imgUrl}
                              className={classes.avatar}
                            />
                          </div>
                          <div
                            style={{
                              paddingBlock: "20px",
                              textAlign: "center",
                            }}
                          >
                            <Typography gutterBottom variant="h5">
                              {individual.name}
                            </Typography>
                            <div className={classes.center}>
                              <LocationOnIcon fontSize="small" />
                              <Typography>{individual.country}</Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                </Grid>
                <Grid container spacing={5}>
                  <Grid item>
                    <div style={{ display: "flex" }}>
                      <Typography className={classes.showingResults}>
                        {totalPosts < postsPerPage
                          ? "Showing results: " +
                            (offSet + 1) +
                            "-" +
                            (offSet + totalPosts) +
                            " of " +
                            totalPosts
                          : "Showing results: " +
                            (offSet + 1 + " - " + (offSet + postsPerPage)) +
                            " of " +
                            totalPosts}
                      </Typography>

                      <ReactPagination
                        containerClassName={classes.paginationContainer}
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={totalNumberOfPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={paginate}
                        activeLinkClassName={classes.paginationActive}
                        disabledClassName={classes.disabledBtn}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mb={3} width={"40%"}>
              <MapView markers={markers} bounds={bounds} mapZoom={mapZoom} />
            </Box>
          </Box>
        );
      case "details":
        return (
          <ConnectProfileDetails userId={userId} navigation={navigation} />
        );
      default:
        break;
    }
  };

  return <>{renderSwitch(id)}</>;
};

export default Profiles;
