import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ProgressLoader() {
  return (
    <div style={{ position: "absolute", top: "50%", left: "50%" }}>
      <CircularProgress style={{ color: "#151515" }} />
    </div>
  );
}
