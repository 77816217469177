import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "../../components/Appbar";
import Footer from "../../components/Footer";
import BusinessProfile from "./BusinessProfile";
import IndividualProfile from "./IndividualProfile";
import { Grid, Typography, Paper } from "@material-ui/core";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    // backgroundColor: "#E3E8EC",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  header: {
    textAlign: "center",
    marginTop: "4%",
    marginBottom: "4%",
  },
  paper: {
    marginBlock: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "36px",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "30px",
  },
}));

function UpdateProfile() {
  const classes = useStyles();

  const account = localStorage.getItem("account");

  const renderSwitch = (account) => {
    switch (account) {
      case "individual":
        return <IndividualProfile />;
      case "business":
        return <BusinessProfile />;
      default:
        return null;
    }
  };

  return (
    <div>
      <AppBar page="createProfile" />
      <Grid container className={classes.content} justify="center">
        <Grid item sm={12} md={8} lg={6}>
          <Typography gutterBottom className={classes.title}>
            Update profile
          </Typography>
          <Typography gutterBottom className={classes.subTitle}>
            Please fill in the details below to update your profile
          </Typography>
          <Paper className={classes.paper}>
            {/* {renderSwitch(account)} */}
            <IndividualProfile />
          </Paper>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}

export default UpdateProfile;
