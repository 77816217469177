import React, { useState, useEffect, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Room from "@material-ui/icons/Room";
import { Link } from "@material-ui/icons";
import { CallMade } from "@material-ui/icons";
import Appbar from "../components/Appbar";
import { Box, Divider, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { db, storageRef } from "../store/firebase/firebase";
import "firebase/firestore";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as TwitterIcon } from "../svgs/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../svgs/linkedin.svg";
import { CountryDropdown } from "react-country-region-selector";
import { PeopleOutline, MailOutline } from "@material-ui/icons";
import UserProfileCard from "./UserProfileCard";
import { calculatePercentageCompleted } from "../utils/calculate";
import { useMediaQuery } from '@material-ui/core';
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";

const options = [
  "IoT Professional",
  "Tech Enthusiast",
  "Data Scientist",
  "Software Developer",
  "Data Analyst",
  "Project Manager",
  "Engineer",
  "AI Specialist",
  "Entrepreneur",
  "Researcher",
  "Industry Expert",
  "Startup Founder",
];

const employeeOptions = ["0 - 15", "16-50", "51-200", "201+"];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    "& .MuiTextField-root": {
      width: "25ch",
    },
  },
  textField: {
    border: "1px solid",
    borderRadius: "8px",
  },
  editAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    position: "relative",
    overflow: "hidden",
  },
  avatarText: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    padding: theme.spacing(1),
    color: "#fff",
    fontSize: "0.6rem",
    // fontWeight: 'bold',
    textAlign: "center",
    width: "100%",
    cursor: "pointer",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [profileType, setProfileType] = useState("");
  const [profileDetails, setProfileDetails] = useState(null);
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loadedTags, setLoadedTags] = useState([]);
  const [projects, setProjects] = useState([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      uploadImage(file);
    }
  };

  const uploadImage = (file) => {
    const userId = localStorage.getItem("user_id");
    const newStorageRef = storageRef.ref(
      `profileImages/${userId}/${file.name}`
    );
    const uploadTask = newStorageRef.put(file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        console.error("Error uploading image:", error);
        setUploading(false);
      },
      () => {
        // Upload completed successfully, get download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          updateProfileImage(downloadURL);
          setUploading(false);
        });
      }
    );
  };

  const updateProfileImage = (imageUrl) => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      const userDocRef = doc(db, "users", userId);
      updateDoc(userDocRef, { profileImage: imageUrl })
        .then(() => {
          console.log("Profile image updated successfully");
        })
        .catch((error) => {
          console.error("Error updating profile image:", error);
        });
    } else {
      console.error("User ID not found in localStorage");
      // Handle the case where userId is not available (e.g., show an error message)
    }
  };

  const handleTagChange = (event, value) => {
    setSelectedTags(value);
  };

  useEffect(() => {
    setSelectedTags(loadedTags);
  }, [loadedTags]);

  useEffect(() => {
    // Fetch profile type from localStorage
    const storedProfileType = localStorage.getItem("account");
    if (storedProfileType) {
      setProfileType(storedProfileType);
    }

    // Fetch profile details from Firebase based on userId
    const userId = localStorage.getItem("user_id");
    if (userId) {
      fetchProfileDetailsFromFirebase(userId);
      fetchProjectsFromFirebase(userId);
    }
  }, [editing, uploading]);

  const fetchProfileDetailsFromFirebase = (userId) => {
    // Sample implementation to fetch profile details from Firebase
    // Replace with your actual Firebase data fetching logic

    const profileRef = (userId) => {
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        getDocs(userDocRef)
          .then((doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              setProfileDetails(userData);
              setUpdatedProfile(userData);
              setLoadedTags(userData.tags || []);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        console.error("User ID not provided for profile fetch.");
        // Handle the case where userId is not available 
      }
    };
  };

    // Calculate percentage completed
    const percentageCompleted = useMemo(() => {
      return calculatePercentageCompleted(profileDetails);
    }, [profileDetails]);

    const fetchProjectsFromFirebase = (userId) => {
      if (userId) {
        const projectsCollectionRef = collection(db, "projects");
        const q = query(projectsCollectionRef, where("userId", "==", userId));
        getDocs(q)
          .then((querySnapshot) => {
            const projectsData = [];
            querySnapshot.forEach((doc) => {
              projectsData.push({ ...doc.data(), id: doc.id }); // Include document ID
            });
            setProjects(projectsData);
          })
          .catch((error) => {
            console.log("Error getting projects:", error);
          });
      } else {
        console.error("User ID not provided for project fetch.");
        // Handle the case where userId is not available 
      }
    };

    const handleEditClick = () => {
      setEditing(true);
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUpdatedProfile({ ...updatedProfile, [name]: value });
    };

    const handleCountryChange = (country) => {
      setUpdatedProfile({ ...updatedProfile, country });
    };

    const handleSaveClick = () => {
      const userId = localStorage.getItem("user_id");
      if (userId && updatedProfile) {
        setIsLoading(true);
        const updatedData = { ...updatedProfile, tags: selectedTags };
        // Filter out undefined fields
        const filteredData = Object.fromEntries(
          Object.entries(updatedData).filter(
            ([key, value]) => value !== undefined
          )
        );
        const userDocRef = doc(db, "users", userId);
        updateDoc(userDocRef, filteredData)
          .then(() => {
            console.log("Profile successfully updated");
            setEditing(false);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error updating profile: ", error);
            setIsLoading(false);
          });
      }
    };

    const onCompletedClick = () => {
      setEditing(true);
    };

    return (
      <div>
        <Appbar />
        <div className={classes.root}>
          <Box
            mb={3}
            width="80%"
            display="flex"
            flexDirection="row-reverse"
            alignItems="center"
          >
            {!editing && percentageCompleted !== 100 && (
              <UserProfileCard
                percentageCompleted={percentageCompleted}
                onCompletedClick={onCompletedClick}
              />
            )}
          </Box>
          {profileType === "Personal" && profileDetails && (
            <Box
              display="flex"
              flexDirection={{
                xs: "column", // For extra small screens and up
                md: "row", // For medium screens and up
              }}
              style={{ gap: "100px" }}
            >
              {/* Individual Details */}
              {!editing ? (
                <Box
                  display="flex"
                  flexDirection={"column"}
                  width={{ xs: "100%", md: 300 }}
                >
                  <Box mb={3}>
                    <Avatar
                      alt="Profile Picture"
                      src={profileDetails.profileImage}
                      className={classes.avatar}
                    />
                  </Box>
                  <Typography variant="h3" gutterBottom>
                    {`${profileDetails.firstName} ${profileDetails.lastName}`}
                  </Typography>
                  <Typography gutterBottom>
                    {profileDetails.bio
                      ? profileDetails.bio.split(/\s+/).slice(0, 50).join(" ") +
                      (profileDetails.bio.split(/\s+/).length > 50 ? "..." : "")
                      : ""}
                  </Typography>

                  <Typography gutterBottom>
                    <span style={{ display: "flex", color: "grey", gap: 2 }}>
                      <Room />
                      {profileDetails.city && `${profileDetails.city},`}{" "}
                      {profileDetails.country ?? ""}
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    <span>
                      <a
                        href={profileDetails.website ?? "?"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Link style={{ color: "grey" }} />
                        {profileDetails.website ?? ""}{" "}
                        {profileDetails.website && (
                          <sup>
                            <CallMade fontSize="small" />
                          </sup>
                        )}
                      </a>
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    <span
                      style={{
                        display: "flex",
                        gap: 4,
                      }}
                    >
                      <TwitterIcon />
                      <LinkedinIcon />
                    </span>
                  </Typography>
                  <Box mb={3}>
                    {loadedTags.map((tag, index) => (
                      <Chip key={index} label={tag} />
                    ))}
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={handleEditClick}
                    style={{ background: "#DFD7FF" }}
                  >
                    Edit Profile
                  </Button>
                </Box>
              ) : (
                <Box width={{ xs: "100%", md: 300 }}>
                  <form
                    noValidate
                    autoComplete="off"
                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                  >
                    <Box mb={3}>
                      <Avatar
                        alt="Change Picture"
                        src={profileDetails.profileImage}
                        className={classes.avatar}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                      />
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="avatar-upload"
                        style={{ cursor: "pointer" }}
                        onClick={() => fileInputRef.current.click()}
                        disabled={uploading}
                      >
                        {uploading ? "Uploading..." : "Change Photo"}
                      </Typography>
                    </Box>
                    <Typography style={{ fontWeight: 600 }}>
                      First Name
                    </Typography>
                    <TextField
                      className={classes.textField}
                      name="firstName"
                      value={updatedProfile.firstName}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>Last Name</Typography>
                    <TextField
                      className={classes.textField}
                      name="lastName"
                      value={updatedProfile.lastName}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>Bio</Typography>
                    <TextField
                      className={classes.textField}
                      name="bio"
                      value={updatedProfile.bio}
                      onChange={handleInputChange}
                      variant="outlined"
                      multiline
                      minRows={4}
                    />

                    <Typography style={{ fontWeight: 600 }}>Country</Typography>
                    <CountryDropdown
                      value={updatedProfile.country}
                      style={{ padding: "16px" }}
                      onChange={handleCountryChange}
                      showDefaultOption={true}
                      classes={classes.textField}
                    />

                    <Typography style={{ fontWeight: 600 }}>City</Typography>
                    <TextField
                      className={classes.textField}
                      name="city"
                      placeholder="Enter city name"
                      value={updatedProfile?.city}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>Website</Typography>
                    <TextField
                      className={classes.textField}
                      name="website"
                      value={updatedProfile?.website}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>
                      Social accounts
                    </Typography>
                    <TextField
                      className={classes.textField}
                      name="twitter"
                      placeholder="Link to twitter"
                      value={updatedProfile?.twitter}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      name="linkedIn"
                      placeholder="Link to linkedIn"
                      value={updatedProfile?.linkedIn}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography variant="h6" gutterBottom>
                      Select tags that represent your expertise, interests, and
                      affiliations.
                    </Typography>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={options}
                      value={selectedTags}
                      onChange={handleTagChange}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select tag"
                        />
                      )}
                    />
                    <Box mt={3} display={"flex"} style={{ gap: 10 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveClick}
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        style={{ background: "#DFD7FF", color: "black" }}
                        onClick={() => setEditing(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
              {/* Individual Projects */}
              <Box display="flex" flexDirection="column">
                <Box width="80px" mb={2}>
                  <Typography variant="h5" style={{ fontWeight: 600 }}>
                    Projects
                  </Typography>
                  <Divider
                    style={{
                      background: "black",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  />
                </Box>
                {projects.length > 0 ? (
                  <Box
                    display="flex"
                    flexDirection={{
                      xs: "column", // For extra small screens and up
                      md: "row", // For medium screens and up
                    }}
                    style={{ gap: "30px" }}
                  >
                    {projects.map((project, index) => (
                      <Box key={index} mb={2}>
                        {project.images[0]?.imageUrl && (
                          <img
                            src={project.images[0].imageUrl}
                            alt={project.name}
                            style={{
                              width: isSmallScreen ? '100%' : '300px', // Set different widths based on screen size
                              maxWidth: '100%' // Ensure the image doesn't exceed its container width
                            }}
                            height={200}
                          />
                        )}
                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                          {project.name}
                        </Typography>
                        {project.tags?.map((tag, tagIndex) => (
                          <Chip key={tagIndex} label={`#${tag}`} />
                        ))}
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="subtitle1">
                    You have no projects
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {profileType === "Business" && profileDetails && (
            <Box
              display="flex"
              flexDirection={{
                xs: "column", // For extra small screens and up
                md: "row", // For medium screens and up
              }}
              style={{ gap: "100px" }}
            >
              {/* Business Details */}
              {!editing ? (
                <Box
                  display="flex"
                  flexDirection={"column"}
                  width={{ xs: "100%", md: 300 }}
                >
                  <Box mb={3}>
                    <Avatar
                      alt="Profile Picture"
                      src={profileDetails.profileImage}
                      className={classes.avatar}
                    />
                  </Box>
                  <Typography variant="h3" gutterBottom>
                    {profileDetails.name}
                  </Typography>
                  <Typography gutterBottom>
                    {profileDetails.bio
                      ? profileDetails.bio.split(/\s+/).slice(0, 50).join(" ") +
                      (profileDetails.bio.split(/\s+/).length > 50 ? "..." : "")
                      : ""}
                  </Typography>
                  <Typography gutterBottom>
                    <span style={{ display: "flex", color: "grey", gap: 2 }}>
                      <PeopleOutline />
                      {profileDetails.numberOfEmployees
                        ? `${profileDetails.numberOfEmployees} employees`
                        : ""}
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    <span style={{ display: "flex", color: "grey", gap: 2 }}>
                      <MailOutline />
                      {profileDetails.email ?? ""}
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    <span style={{ display: "flex", color: "grey", gap: 2 }}>
                      <Room />
                      {profileDetails.city && `${profileDetails.city},`}{" "}
                      {profileDetails.country ?? ""}
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    <span>
                      <a
                        href={profileDetails.website ?? "?"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Link style={{ color: "grey" }} />
                        {profileDetails.website ?? ""}{" "}
                        {profileDetails.website && (
                          <sup>
                            <CallMade fontSize="small" />
                          </sup>
                        )}
                      </a>
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    <span
                      style={{
                        display: "flex",
                        gap: 4,
                      }}
                    >
                      <TwitterIcon />
                      <LinkedinIcon />
                    </span>
                  </Typography>
                  <Box mb={3}>
                    {loadedTags.map((tag, index) => (
                      <Chip key={index} label={tag} />
                    ))}
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={handleEditClick}
                    style={{ background: "#DFD7FF" }}
                  >
                    Edit Profile
                  </Button>
                </Box>
              ) : (
                <Box width={{ xs: "100%", md: 300 }}>
                  <form
                    noValidate
                    autoComplete="off"
                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                  >
                    <Box mb={3}>
                      <Avatar
                        alt="Change Picture"
                        src={profileDetails.profileImage}
                        className={classes.avatar}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                      />
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="avatar-upload"
                        style={{ cursor: "pointer" }}
                        onClick={() => fileInputRef.current.click()}
                        disabled={uploading}
                      >
                        {uploading ? "Uploading..." : "Change Photo"}
                      </Typography>
                    </Box>
                    <Typography style={{ fontWeight: 600 }}>
                      Business Name
                    </Typography>
                    <TextField
                      className={classes.textField}
                      name="name"
                      value={updatedProfile.name}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>Bio</Typography>
                    <TextField
                      className={classes.textField}
                      name="bio"
                      value={updatedProfile.bio}
                      onChange={handleInputChange}
                      variant="outlined"
                      multiline
                      minRows={4}
                    />

                    <Typography style={{ fontWeight: 600 }}>
                      Number of employees
                    </Typography>
                    <TextField
                      select
                      placeholder="Select Option"
                      className={classes.textField}
                      name="numberOfEmployees"
                      value={updatedProfile.numberOfEmployees}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                    >
                      {employeeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <Typography style={{ fontWeight: 600 }}>Country</Typography>
                    <CountryDropdown
                      value={updatedProfile.country}
                      style={{ padding: "16px" }}
                      onChange={handleCountryChange}
                      showDefaultOption={true}
                      classes={classes.textField}
                    />

                    <Typography style={{ fontWeight: 600 }}>City</Typography>
                    <TextField
                      className={classes.textField}
                      name="city"
                      placeholder="Enter city name"
                      value={updatedProfile?.city}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>Website</Typography>
                    <TextField
                      className={classes.textField}
                      name="website"
                      value={updatedProfile?.website}
                      onChange={handleInputChange}
                      variant="outlined"
                    />

                    <Typography style={{ fontWeight: 600 }}>
                      Social accounts
                    </Typography>
                    <TextField
                      className={classes.textField}
                      name="twitter"
                      placeholder="Link to twitter"
                      value={updatedProfile?.twitter}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      name="linkedIn"
                      placeholder="Link to linkedIn"
                      value={updatedProfile?.linkedIn}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                    <Box mt={3} display={"flex"} style={{ gap: 10 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveClick}
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        style={{ background: "#DFD7FF", color: "black" }}
                        onClick={() => setEditing(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
              {/* Business Projects */}
              <Box display="flex" flexDirection="column">
                <Box width="80px" mb={2}>
                  <Typography variant="h5" style={{ fontWeight: 600 }}>
                    Projects
                  </Typography>
                  <Divider
                    style={{
                      background: "black",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  />
                </Box>
                {projects.length > 0 ? (
                  <Box
                    display="flex"
                    flexDirection={{
                      xs: "column", // For extra small screens and up
                      md: "row", // For medium screens and up
                    }}
                    style={{ gap: "30px" }}
                  >
                    {projects.map((project, index) => (
                      <Box key={index} mb={2}>
                        {project.images[0]?.imageUrl && (
                          <img
                            src={project.images[0].imageUrl}
                            alt={project.name}
                            style={{ width: "-webkit-fill-available" }}
                            height={200}
                          />
                        )}
                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                          {project.name}
                        </Typography>
                        {project.tags?.map((tag, tagIndex) => (
                          <Chip key={tagIndex} label={`#${tag}`} />
                        ))}
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="subtitle1">
                    You have no projects
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </div>
      </div>
    );
  };

  export default Profile;
