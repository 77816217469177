import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
}));

export default function ProductImageUpload({ onDrop, accept }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F9F9F9",
          border: "1px dashed #ABB3BF",
          height: 220,
          width: "inherit",
          borderRadius: 5,
          flexDirection: "column",
          marginBottom: 40,
          cursor: "pointer",
        }}
        {...getRootProps()}
      >
        <input className="dropzone-input" {...getInputProps()} />
        <BackupOutlinedIcon style={{ fontSize: 45, color: "#333" }} />
        <div>
          {isDragActive ? (
            <Typography
              style={{
                color: "#333",
                padding: "5px 20px",
                textAlign: "center",
              }}
            >
              Release to drop the files here
            </Typography>
          ) : (
            <Typography
              style={{
                color: "#333",
                padding: "5px 20px",
                textAlign: "center",
              }}
            >
              Drag and drop an image or browse to choose a file
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
