import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
import {
  Button,
  Paper,
  Grid,
  Link,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import GoogleButton from "../../utils/GoogleButton";
// import FacebookButton from "../../utils/FacebookButton";
import GithubButton from "../../utils/GithubButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import AppBar from "../../components/Appbar";
import Footer from "../../components/Footer";
import { loginUser } from "../../store/actions";
import validate from "validate.js";
import SignupAlert from "../../components/Alert/SignupAlert";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    fontSize: 28,
    lineHeight: "36px",
    color: "#263339",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#999",
    marginBottom: theme.spacing(2),
  },
  description: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: " #605A5A",
    marginLeft: 10,
  },
  container: {
    margin: "3% 0",
    padding: theme.spacing(3, 4),
  },
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#460CEB",
    color: "#fff",
    "&$disabled": {
      background: "#E7E9EE",
      color: "#ABB3BF",
    },
  },
  disabled: {},
  policy: {
    color: "#ABB3BF",
    fontWeight: 400,
    fontSize: 12,
    textAlign: "center",
    marginBottom: theme.spacing(2.5),

    "& a": {
      color: "#ABB3BF",
      textDecoration: "underline",
      paddingTop: 7,
    },
  },
  subText: {
    color: "#605A5A",
    fontWeight: 400,
    fontSize: 12,

    "& a": {
      color: "#460CEB",
    },
  },
  eye: {
    cursor: "pointer",
  },
}));

const Benefits = (props) => {
  const { text } = props;
  return (
    <div style={{ display: "flex", marginBottom: 5 }}>
      <img src="../../../assets/checked.svg" alt="" />
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: " #605A5A",
          marginLeft: 10,
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

function Login(props) {
  const classes = useStyles();
  const [passwordShown, setPasswordShown] = useState(false);
  const { dispatch, isAuthenticated, loginError } = props;
  const [open, setOpen] = useState(false);
  console.log('%csrc/views/Login/index.js:143 isAuthenticated', 'color: #007acc;', isAuthenticated, !loginError, loginError);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { subscribe: false },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    loginError ? setOpen(true) : setOpen(false);

    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, loginError]);

  const handleLogin = (event) => {
    event.preventDefault();
    const email = formState.values.email;
    const password = formState.values.password;
    dispatch(loginUser(email, password));
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return isAuthenticated && !loginError ? (
    <Redirect to="/" />
  ) : (
    <div>
      <div>
        <AppBar />
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        spacing={5}
        className={classes.content}
      >
        <Grid item sm={5} md={5} lg={4} style={{ marginTop: "8%" }}>
          <Typography gutterBottom className={classes.title}>
            Welcome back!
          </Typography>
          <Typography className={classes.subTitle}>
            Login to access account
          </Typography>
          <Benefits text="Catch up on recent news" />
          <Benefits text="Connect with more people" />
          <Benefits text="Learn more on things" />
          <Benefits text="Get in touch with professionals" />
        </Grid>

        <Grid item sm={7} md={6} lg={5}>
          {loginError ? (
            <SignupAlert
              close={handleClose}
              message={loginError}
              open={open}
              // severity={severity}
            />
          ) : null}
          <Paper elevation={3} className={classes.container}>
            <Typography className={classes.label} gutterBottom>
              Email address
            </Typography>
            <TextField
              name="email"
              variant="outlined"
              size="small"
              type="email"
              className={classes.field}
              fullWidth
              placeholder="Enter email address"
              onChange={handleChange}
              error={hasError("email")}
              helperText={hasError("email") ? "Invalid email address" : null}
            />

            <Typography className={classes.label} gutterBottom>
              Password
            </Typography>
            <TextField
              name="password"
              variant="outlined"
              size="small"
              type={passwordShown ? "text" : "password"}
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              className={classes.field}
              onChange={handleChange}
              fullWidth
              placeholder="Enter password here"
              InputProps={{
                className: classes.inputColor,
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <i
                      onClick={togglePasswordVisibility}
                      className={classes.eye}
                    >
                      {passwordShown ? (
                        <VisibilityOutlinedIcon fontSize="small" />
                      ) : (
                        <VisibilityOffOutlinedIcon fontSize="small" />
                      )}
                    </i>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              disabled={!formState.isValid}
              onClick={handleLogin}
              fullWidth
              className={classes.button}
              variant="contained"
              classes={{
                root: classes.button,
                disabled: classes.disabled,
              }}
            >
              Login
            </Button>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography className={classes.subText}>
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/signup">
                    Sign up
                  </Link>
                </Typography>
              </div>
              <div>
                <Typography className={classes.subText}>
                  {/* <Link
                  component={RouterLink}
                  // to="/signup"
                > */}
                  Forgot Password?
                  {/* </Link> */}
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
