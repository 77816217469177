import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const UserProfileCard = ({ percentageCompleted, onCompletedClick }) => {
  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" gutterBottom>
            Almost there! Complete your profile to make the most out of your
            experience. It only takes a few minutes.
          </Typography>
          <Box mt={2} width="300px">
            <LinearProgress
              variant="determinate"
              value={percentageCompleted}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onCompletedClick}
            >
              Complete
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserProfileCard;
