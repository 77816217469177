import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

function ChallengeCardSkeleton() {
  return (
    <Grid item container spacing={4} style={{marginBottom: "20px"}}>
      <Grid item xs={4} md={2} >
        <Skeleton
          width="100%"
          height="100%"
          variant="rect"
          style={{ borderRadius: "4px" }}
        />
      </Grid>
      <Grid item xs={10} md={8}>
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="80%" />
      </Grid>
    </Grid>
  );
}

export default ChallengeCardSkeleton;