import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Appbar from "../../components/Appbar";
import Footer from "../../components/Footer";
import ReactPaginate from "react-paginate";
import ChallengeCard from "./ChallengeCard";
import ChallengeCardSkeleton from "./ChallengeCardSkeleton";
import { db } from "../../store/firebase/firebase";
import { collection, doc, updateDoc, getDocs, query, where } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  heroSection: {
    background: "#F4F7FB",
    padding: "20px 50px 0px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heroTitle: {
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "58px",
    color: "#180E40",
  },
  paginationContainer: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    marginTop: "35px",
    position: "absolute",
    right: 90,
    bottom: 31,
    "& a": {
      boxSizing: "border-box",
      borderRadius: "3px",
      padding: "10px 15px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      margin: "0px 2px",
      "&:hover": {
        background: "#180E40",
        color: "#fff",
      },
    },
  },
  paginationActive: {
    background: "#180E40",
    color: "#fff",
    border: "none",
  },
  activeLink: {
    padding: "6px 0px 8px",
  },
  disabledBtn: {
    color: "#ABB3BF",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(24, 14, 64, 1)",
    lineHeight: "30px",
  },
}));

function Challenges(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 5;
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const lastItemOnPage = firstItemOnPage + itemsPerPage;
  const currentItemsOnPage =
    data && data.slice(firstItemOnPage, lastItemOnPage);
  const numberOfPages = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (e) => {
    const newfirstItemOnPage = (e.selected * itemsPerPage) % data.length;
    setFirstItemOnPage(newfirstItemOnPage);
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const lists = [];
      try {
        const challengesCollectionRef = collection(db, "challenges");
        const querySnapshot = await getDocs(challengesCollectionRef);
        querySnapshot.forEach((doc) => {
          lists.push({ ...doc.data(), id: doc.id });
        });
        setData(lists);
      } catch (error) {
        console.error("Error fetching challenges:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  // ... rest of your component logic using 'currentItemsOnPage' and 'numberOfPages'

  // console.log(data);
  return (
    <div>
      <Appbar />
      <Grid container xs={12} style={{ position: "relative" }}>
        <Grid item container md={12} className={classes.heroSection}>
          <Grid item md={8}>
            <Typography className={classes.heroTitle}>
              Take part in challenges
              <br /> to earn prizes
            </Typography>
          </Grid>
          <Grid item md={4}>
            <img
              width="294px"
              height="254px"
              src="./assets/challengesHero.png"
              alt=""
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          style={{ padding: "70px", marginLeft: "30px" }}
        >
          {loading ? (
            <>
              <ChallengeCardSkeleton />
              <ChallengeCardSkeleton />
              <ChallengeCardSkeleton />
              <ChallengeCardSkeleton />
              <ChallengeCardSkeleton />
              <ChallengeCardSkeleton />
              <ChallengeCardSkeleton />
            </>
          ) : (
            ""
          )}
          {currentItemsOnPage &&
            currentItemsOnPage.map((data) => (
              <ChallengeCard
                id={data.id}
                title={data.title}
                description={data.description}
                imgUrl={data.bannerUrl}
                deadline={data.endDate}
              />
            ))}
        </Grid>
        {data.length !== 0 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={numberOfPages}
            previousLabel="<"
            containerClassName={classes.paginationContainer}
            activeClassName={classes.paginationActive}
            pageClassName={classes.activeLink}
            disabledClassName={classes.disabledBtn}
          />
        ) : (
          ""
        )}
      </Grid>
      <Footer />
    </div>
  );
}

export default Challenges;
